import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UsuariosService } from '../services/Usuarios.service';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardLogin implements CanActivate {
  constructor(private authService: UsuariosService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

      return new Promise<boolean>((resolve, reject) => {
         // Verifica si el usuario está autenticado
         if(this.authService.getToken()!=null){
           this.authService.isTokenExpired().subscribe(isExpired=>{
             if(!isExpired){
              let modulos:string = String(localStorage.getItem("modulos"))
              if(modulos.includes("SOLICITUDES")){
                this.router.navigate(['/simex/solicitudes']);
              }else if(modulos.includes("USUARIOS")){
                this.router.navigate(['/simex/usuarios']);
              }else if(modulos.includes("SERVICIOS")){
                this.router.navigate(['/simex/secciones-servicios']);
              }else if(modulos.includes("CLIENTES")){
                this.router.navigate(['/simex/clientes']);
              }else{
                this.router.navigate(['/simex/inicio']);
              }

               resolve(false)
             }else{
               resolve(true)
             }
           },error=>{
             resolve(true)
             //reject(error)
           })
         }else{
           //this.router.navigate(['/login']);
           resolve(true)
         }
      })
  }
}
