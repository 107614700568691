import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { RolService } from "src/app/services/rol.service";
import { UsuariosService } from "src/app/services/Usuarios.service";
import { Usuario } from "src/app/models/Usuario";
import { Rol } from "src/app/models/Rol";
import swal from "sweetalert2";
@Component({
  selector: "app-editar-usuario",
  templateUrl: "editar-usuario.component.html"
})
export class EditarUsuarioComponent implements OnInit {
  id:number
  lstRoles:Rol[] = []
  lstRolesToSelect:any[] = []
  usuario:Usuario = new Usuario()
  constructor(private usuariosService:UsuariosService, private rolService:RolService, private router: Router, private route: ActivatedRoute){}

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.findById()

  }

  findById(){
    this.usuariosService.findById(this.id).subscribe(success=>{
      this.usuario = success
      this.usuario.password = ""
      this.findAllRol()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  findAllRol(){
    this.rolService.findAll().subscribe(success=>{
      this.lstRoles = success
      for(let rol of this.lstRoles){

        this.lstRolesToSelect.push(
          {
            ...rol, ["activo"]:rol.nombre=='Usuario'?true:false
          }
        )
      }
      this.setExistingRoles()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  setExistingRoles(){
    for(let rol of this.lstRolesToSelect){
      if(this.usuario.roles.find((x) => x.id === rol.id) != undefined){
        rol.activo = true
      }
    }
    this.usuario.roles = []
  }

  changeActiveRol(i:number){
    this.lstRolesToSelect[i].activo = !this.lstRolesToSelect[i].activo
    console.log(this.lstRolesToSelect)
  }

  changeActiveUser(){
    this.usuario.activo = !this.usuario.activo
  }

  saveAlert(){
    var that = this
    swal.fire({
      title: '¿Estas seguro que deseas actualizar este usuario?',
      showDenyButton: true,
      confirmButtonText: 'Actualizar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.update()
      }
    })
  }

  update(){
    for(let rol of this.lstRolesToSelect){
      if(rol.activo){
        this.usuario.roles.push(
          {
            "id":rol.id,
            "nombre":rol.nombre,
            "clave":rol.clave
          }
        )
      }
    }
    this.usuariosService.update(this.usuario).subscribe(success=>{
      this.swalNotification("Excelente!!","El usuario fue actualizado correctamente","success")
      this.router.navigate(['/simex/usuarios'])
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })

  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }
}
