<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a routerLink="/simex/secciones-servicios"> Secciones </a>
              </li>

              <li class="breadcrumb-item">
                <a> Editar sección </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-danger text-white"
            routerLink="/simex/secciones-servicios"
            style="cursor: pointer"
          >
            Cancelar
          </a>

          <a class="btn btn-sm btn-neutral" style="cursor: pointer" (click)="saveAlert()">
            Actualizar
          </a>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>
          <h2 class="h3 mb-0">Nueva sección</h2>
        </div>

        <hr class="my-3" />

        <div class="card-body">
          <form>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer01">
                    Nombre sección
                  </label>

                  <input
                    class="form-control"
                    id="validationServer01"
                    placeholder="Nombre"
                    required=""
                    name="nombre"
                    type="text"
                    [(ngModel)]="seccion.nombre"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Servicio</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of seccion.servicios, index as i">
                <td>{{item.nombre}}</td>
                <td>
                  <a *ngIf="item.id!=null" class="btn btn-sm" [ngClass]="item.eliminado?'btn-success':'btn-danger'" style="cursor: pointer" (click)="removeServicio(i)">
                    <h3 class="text-white">
                      {{item.eliminado?"Activar":"Desactivar"}}
                    </h3>
                  </a>

                  <a *ngIf="item.id==null" class="btn btn-sm btn-danger" style="cursor: pointer" (click)="removeServicio(i)">
                    <h3 class="text-white">
                      Remover
                    </h3>
                  </a>
                </td>
              </tr>
              <tr>
                <td>

                  <div class="form-group">
                    <label class="form-control-label" for="validationServer01">
                      Nombre servicio
                    </label>

                    <input
                      class="form-control"
                      id="validationServer01"
                      placeholder="Nombre servicio"
                      required=""
                      name="nombreservicio"
                      type="text"
                      [(ngModel)]="servicio.nombre"
                    />
                  </div>

                </td>
                <td>
                  <a class="btn btn-sm btn-success" style="cursor: pointer" (click)="addServicio()">
                    <h3 class="text-white">
                      Agregar
                    </h3>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
