import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormsModule } from "@angular/forms";

import { RouterModule } from "@angular/router";
import { VerClienteComponent } from "./ver-cliente.component";

import { NgxDatatableModule } from "@swimlane/ngx-datatable";

@NgModule({
  declarations: [VerClienteComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxDatatableModule
  ]
})
export class VerClienteModule {}
