<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> Usuarios </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class="col-lg-6 col-5 text-right">
          <a class="btn btn-sm btn-neutral" routerLink="/simex/usuarios/nuevo-usuario" style="cursor: pointer;">
            Agregar
          </a>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>

          <h2 class="h3 mb-0">Usuarios</h2>
        </div>
        <!--
        <div class="table-responsive">
          <ngx-datatable
            class="bootstrap selection-cell"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="10"
            [rows]="temp"
          >
            <ngx-datatable-column name="Nombre" [width]="300">
              <ng-template class="table-user" ngx-datatable-cell-template let-row="row">
                <img
                    class="avatar rounded-circle mr-3"
                    [src]="getFoto(row.foto_perfil)"
                  />

                <b>{{row.nombre + " " + row.apellido_paterno}}</b>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="Usuario"
              prop="nombre_usuario"
            ></ngx-datatable-column>
            <ngx-datatable-column
              name="Fecha de creación"
              prop="fecha_creacion"
            ></ngx-datatable-column>
            <ngx-datatable-column
              name="Ultima actualización"
              prop="fecha_actualizacion"
            ></ngx-datatable-column>

            <ngx-datatable-column name="Acciones" [width]="300">
              <ng-template ngx-datatable-cell-template let-row="row">

                <button
                  class="btn btn-success"
                  (click)="goToEdit(row.id)"
                >
                  Editar &nbsp;
                  <i class="fas fa-user-edit"> </i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      -->
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Nombre</th>
                <th>Usuario</th>
                <th>Fecha de creación</th>

                <th>Ultima actualización</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstUsuarios; index as i">
                <td class="table-user">
                  <img
                    class="avatar rounded-circle mr-3"
                    [src]="getFoto(item.foto_perfil)"
                  />

                  <b> {{ item.nombre + " " + item.apellido_paterno }}</b>
                </td>
                <td>
                  <span class="text-muted">{{ item.nombre_usuario }}</span>
                </td>
                <td>
                  <span class="text-muted"> {{ item.fecha_creacion }}</span>
                </td>

                <td>
                  <span class="text-muted">{{ item.fecha_actualizacion }}</span>
                </td>

                <td class="table-actions">
                  <button
                  class="btn btn-success"
                  (click)="goToEdit(item.id)"
                >
                  Editar &nbsp;
                  <i class="fas fa-user-edit"> </i>
                </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer py-4">
          <nav aria-label="...">
            <pagination
              [totalItems]="totalElements"
              class="justify-content-end mb-0"
              [customPreviousTemplate]="prevTemplate"
              [customNextTemplate]="nextTemplate"
              [itemsPerPage]="size"
              [(ngModel)]="page"
              (pageChanged)="pageChanged($event)"
              [maxSize]="5"
            >
            </pagination>

            <ng-template #nextTemplate let-currentPage="currentPage">
              <ng-container *ngIf="!disabled">
                <i class="fas fa-angle-right"></i>
              </ng-container>
            </ng-template>

            <ng-template #prevTemplate let-currentPage="currentPage">
              <ng-container *ngIf="!disabled">
                <i class="fas fa-angle-left"></i>
              </ng-container>
            </ng-template>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
