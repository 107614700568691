import { Component, OnInit, OnDestroy } from "@angular/core";
import { Cliente } from "src/app/models/Cliente";
import { CatalogoService } from "src/app/services/catalogo.service";
import swal from "sweetalert2";
import * as $ from 'jquery';
import { Router } from "@angular/router";
@Component({
  selector: "app-clientes",
  templateUrl: "clientes.component.html"
})
export class ClientesComponent implements OnInit, OnDestroy {
  page:number = 1
  size:number = 5
  totalElements:number = 0
  inputBusqueda:string = ""

  lstClientes:Cliente[] = []
  temp:Cliente[] = []
  crearPermission:boolean = false
  editarPermission:boolean = false
  crearPermissionSolicitudes:boolean = false
  constructor(private catalogoService:CatalogoService, private router: Router) {}


  ngOnInit() {
    let permisos:any[] = JSON.parse(localStorage.getItem("modulos"))
    let mp:any = permisos.find(x => x["modulo"]==="CLIENTES")
    if(mp["permisos"]["crear"]){
      this.crearPermission = true
    }
    if(mp["permisos"]["editar"]){
      this.editarPermission = true
    }
    let mps:any = permisos.find(x => x["modulo"]==="SOLICITUDES")
    if(mps!=undefined){
      if(mps["permisos"]["crear"]){
        this.crearPermissionSolicitudes = true
      }
    }

    var that = this
    $(document).ready(function() {
      // Seleccionamos el input por su ID
      var $miInput = $("#input-buscar-cliente");

      // Agregamos el evento keypress al input
      $miInput.on("keypress", function(event) {
        // Comprobamos si la tecla presionada es "Enter" (código 13)
        if (event.which === 13) {
          // Aquí puedes realizar la acción que desees cuando se presione "Enter"
          that.searchData()
          // Evitamos el comportamiento por defecto del Enter en un formulario
          event.preventDefault();
        }
      });
    });



    this.findAllCliente()
  }

  ngOnDestroy(): void {
    $("#input-buscar-cliente").val("")
    //throw new Error("Method not implemented.");
  }

  findAllCliente(){
    const formData = new FormData()
    formData.append("filtro",this.inputBusqueda)
    formData.append("page",String(this.page-1))
    formData.append("size",String(this.size))
    this.catalogoService.findAllCliente(formData).subscribe(success=>{
      this.lstClientes = success["content"]
      this.totalElements = success["totalElements"]
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  sweetAlert(icon,title:string,button:string,text:string){
    swal.fire({
      title: title,
      text: "",
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  goToEdit(id:number){
    this.router.navigate([`/simex/clientes/editar-cliente/${id}`]);
  }

  goToVer(id:number){
    this.router.navigate([`/simex/clientes/ver-cliente/${id}`]);
  }

  goToCrearSolicitud(id:number){
    this.router.navigate([`/simex/solicitudes/nueva-solicitud/${id}`]);
  }

  searchData(){
    this.inputBusqueda = String($("#input-buscar-cliente").val())
    this.page = 1
    this.findAllCliente()
    /*
    this.temp = this.lstClientes.filter((item) => {
      // Modifica esta lógica según tus necesidades
      return (
        item.razon_social.toUpperCase().includes(search.toUpperCase()) ||
        item.representante_legal.toUpperCase().includes(search.toUpperCase()) ||
        item.fecha_creacion.toUpperCase().includes(search.toUpperCase())
      );
    });*/
  }

  pageChanged($event){
    this.page = $event["page"]
    this.findAllCliente()
  }

}
