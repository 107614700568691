import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormsModule } from "@angular/forms";

import { RouterModule } from "@angular/router";
import { SeccionesServiciosComponent } from "./secciones-servicios.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";

@NgModule({
  declarations: [SeccionesServiciosComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ModalModule.forRoot(),
    NgxDatatableModule,
    PaginationModule.forRoot(),
  ]
})
export class SeccionesServiciosModule {}
