import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Solicitud } from "src/app/models/Solicitud";
import { SolicitudService } from "src/app/services/solicitud.service";
import swal from "sweetalert2";
import * as $ from 'jquery';

@Component({
  selector: "app-solicitudes",
  templateUrl: "solicitudes.component.html"
})
export class SolicitudesComponent implements OnInit {

  solicitudes:Solicitud[] = []
  page:number = 1
  size:number = 5
  totalElements:number = 0
  inputBusqueda:string = ""
  filtro = {
    folio: '',
    cliente: '',
    estatus: '',
    cantidad: '',
    fecha: ''
  };
  constructor(private solicitudService:SolicitudService, private router: Router) {}

  ngOnInit() {

    var that = this
    $(document).ready(function() {
      // Seleccionamos el input por su ID
      var $miInput = $("#input-buscar-solicitud");

      // Agregamos el evento keypress al input
      $miInput.on("keypress", function(event) {
        // Comprobamos si la tecla presionada es "Enter" (código 13)
        if (event.which === 13) {
          // Aquí puedes realizar la acción que desees cuando se presione "Enter"
          that.searchData()
          // Evitamos el comportamiento por defecto del Enter en un formulario
          event.preventDefault();
        }
      });
    });

    this.findAll()
  }

  findAll(){
    const formData = new FormData()
    formData.append("filtro",this.inputBusqueda)
    formData.append("page",String(this.page-1))
    formData.append("size",String(this.size))
    this.solicitudService.findAll(formData).subscribe(success=>{
      this.solicitudes = success["content"]
      this.totalElements = success["totalElements"]
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  sweetAlert(icon,title:string,button:string,text:string){
    swal.fire({
      title: title,
      text: "",
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  getEstatusClass(estatus:string):string{
    if(estatus=="PENDIENTE"){
      return "bg-warning"
    }else if(estatus=="SOLICITUD DE INFORMACION"){
      return "bg-info"
    }else if (estatus=="EN REVISION") {
      return "bg-warning"
    }else if (estatus=="EN PROCESO") {
      return "bg-success"
    }else if (estatus=="FINALIZADO") {
      return "bg-danger"
    }
  }

  goToSolicitud(id:number){
    this.router.navigate([`/simex/solicitudes/solicitud/${id}`]);
  }

  searchData(){
    this.inputBusqueda = String($("#input-buscar-solicitud").val())
    this.page = 1
    this.findAll()
    /*
    this.temp = this.solicitudes.filter((item) => {
      // Modifica esta lógica según tus necesidades
      return (
        item.folio.toUpperCase().includes(search.toUpperCase()) ||
        item.cliente.razon_social.toUpperCase().includes(search.toUpperCase()) ||
        item.estatus_solicitud.nombre.toUpperCase().includes(search.toUpperCase()) ||
        item.servicios.length.toString().includes(search)
      );
    });
    */
  }

  pageChanged($event){
    this.page = $event["page"]
    this.findAll()
  }
}
