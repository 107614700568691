<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a> Solicitudes </a>
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!--<a
            class="btn btn-sm btn-neutral"
            routerLink="/simex/solicitudes/nueva-solicitud"
            style="cursor: pointer"
          >
            Agregar
          </a>

          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-lg-6">
              <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>
              <h2 class="h3 mb-0">Solicitudes</h2>
            </div>
            <div class="col-lg-6 text-right">
              <button class="btn btn-link" (click)="findAll()">
                <i class="fa fa-recycle" aria-hidden="true"></i>
                Refrescar</button>
            </div>
          </div>
        </div>

        <!--
        <ngx-datatable
          class="bootstrap selection-cell"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [limit]="10"
          [rows]="temp"
        >
        <ngx-datatable-column name="Folio" prop="folio"></ngx-datatable-column>
          <ngx-datatable-column name="Cliente" prop="cliente.razon_social"></ngx-datatable-column>
          <ngx-datatable-column name="Estatus" prop="estatus_solicitud.clave">
            <ng-template ngx-datatable-cell-template let-row="row">

              <span class=" badge badge-dot mr-4">
                <i [class]="getEstatusClass(row.estatus_solicitud.clave)+' bg-dot'"> </i>
                <span class=" status"> {{row.estatus_solicitud.nombre}} </span>
              </span>

            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Cantidad de servicios" prop="servicios.length">
            <ng-template ngx-datatable-cell-template let-row="row">
              <span class="text-center">
                {{row.servicios.length}}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Fecha creación" prop="fecha_creacion"></ngx-datatable-column>
          <ngx-datatable-column name="Fecha actualización" prop="fecha_actualizacion"></ngx-datatable-column>
          <ngx-datatable-column name="Acciones">
            <ng-template ngx-datatable-cell-template let-row="row">
              <button class="btn btn-secondary" (click)="goToSolicitud(row.id)">
                Ir a solicitud
              </button>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        -->
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th scope="col">Folio</th>
                <th scope="col">Cliente</th>
                <!--
                <th scope="col">Estatus</th>
                -->
                <th scope="col">Cantidad de servicios</th>
                <th scope="col">Fecha creación</th>
                <th scope="col">Fecha actualización</th>
                <th scope="col" style="text-align: center;">
                  Acciones
                </th>
              </tr>
            </thead>

            <tbody class="list">
              <tr *ngFor="let item of solicitudes; index as i">
                <td scope="row">
                  <span class="name mb-0 text-sm">
                    <strong>
                      {{ item.folio }}
                    </strong>
                  </span>
                </td>
                <td>{{ item.cliente.razon_social }}</td>
                <!--
                <td>
                  <span class="badge badge-dot mr-4">
                    <i
                      [class]="
                        getEstatusClass(item.estatus_solicitud.clave) + ' bg-dot'
                      "
                    >
                    </i>
                    <span class="status"> {{ item.estatus_solicitud.nombre }} </span>
                  </span>
                </td>
                -->
                <td>
                  <span class="text-center">
                    {{item.servicios.length}}
                  </span>
                </td>
                <td>{{item.fecha_creacion}}</td>
                <td>{{item.fecha_actualizacion}}</td>
                <td>
                  <button class="btn btn-secondary" (click)="goToSolicitud(item.id)">
                    Ir a solicitud
                  </button>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
        <div class=" card-footer py-4">
          <nav aria-label="...">
            <pagination [totalItems]="totalElements"
                        class="justify-content-end mb-0"
                        [customPreviousTemplate]="prevTemplate"
                        [customNextTemplate]="nextTemplate"
                        [itemsPerPage]="size"
                        [(ngModel)]="page"
                        (pageChanged)="pageChanged($event)"
                        [maxSize]="5">
            </pagination>

            <ng-template #nextTemplate let-currentPage="currentPage">
              <ng-container *ngIf="!disabled">
                <i class='fas fa-angle-right'></i>
              </ng-container>
            </ng-template>

            <ng-template #prevTemplate let-currentPage="currentPage">
              <ng-container *ngIf="!disabled">
                <i class='fas fa-angle-left'></i>
              </ng-container>
            </ng-template>

          </nav>
        </div>

      </div>
    </div>
  </div>
</div>
<style>
  .bg-dot {
    height: 1rem;
    width: 1rem;
  }
</style>
