import { Rol } from "./Rol";
export class Usuario{
  id:number
  nombre:string=""
  apellido_paterno:string = ""
  apellido_materno:string = ""
  password:string = ""
  nombre_usuario:string = ""
  activo:boolean =true
  roles: Rol[] = []
  foto_perfil:string
  email:string
  passemail:string
}
