<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a routerLink="/simex/usuarios"> Usuarios </a>
              </li>

              <li class="breadcrumb-item">
                <a> Editar usuario </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-danger text-white"
            routerLink="/simex/usuarios"
            style="cursor: pointer"
          >
            Cancelar
          </a>

          <a
            class="btn btn-sm btn-neutral"
            style="cursor: pointer"
            (click)="saveAlert()"
          >
            Actualizar
          </a>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<!--
<div class=" header pb-6">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-4">
        <div class=" col-lg-6 col-7">

          <nav
            aria-label="breadcrumb"
            class=" d-none d-md-inline-block ml-md-4"
          >
            <ol class=" breadcrumb breadcrumb-links">
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
              </li>

              <li class=" breadcrumb-item">
                <a routerLink="/simex/usuarios"> Usuarios </a>
              </li>

              <li class=" breadcrumb-item">
                <a> Nuevo usuario </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class=" col-lg-6 col-5 text-right">

        </div>
      </div>
    </div>
  </div>
</div>
-->
<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-md-10">
              <h6 class="text-muted text-uppercase ls-1 mb-1">
                Administración
              </h6>
              <h2 class="h3 mb-0">Editar usuario</h2>
            </div>
            <div class="col-md-2">
              <h6 class="text-muted text-uppercase ls-1 mb-1">Activo</h6>
              <label class="custom-toggle custom-toggle-success mr-1">
                <input
                  type="checkbox"
                  [checked]="usuario.activo == true ? 'checked' : ''"
                  (change)="changeActiveUser()"
                />
                <span class="custom-toggle-slider rounded-circle"></span>
              </label>
            </div>
          </div>
        </div>
        <hr class="my-3" />

        <div class="card-body">
          <div class="form-row">
            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label class="form-control-label" for="validationServer01">
                  Nombre
                </label>

                <input
                  class="form-control"
                  id="validationServer01"
                  placeholder="Nombre"
                  required=""
                  name="nombre"
                  type="text"
                  [(ngModel)]="usuario.nombre"
                />

                <!--<div class="valid-feedback">Looks good!</div>-->
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label class="form-control-label" for="validationServer02">
                  Apellido paterno
                </label>

                <input
                  class="form-control"
                  id="validationServer02"
                  placeholder="Apellido paterno"
                  required=""
                  name="apellido_paterno"
                  type="text"
                  [(ngModel)]="usuario.apellido_paterno"
                />

                <!--<div class="valid-feedback">Looks good!</div>-->
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label
                  class="form-control-label"
                  for="validationServerUsername"
                >
                  Apellido materno
                </label>

                <input
                  aria-describedby="inputGroupPrepend3"
                  class="form-control"
                  id="validationServerUsername"
                  placeholder="Apellido materno"
                  required=""
                  name="apellido_materno"
                  type="text"
                  [(ngModel)]="usuario.apellido_materno"
                />
              </div>
            </div>
          </div>
          <div class="form-row">

            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label class="form-control-label" for="validationServer01">
                  Email
                </label>

                <input
                  class="form-control"
                  id="validationServer01"
                  placeholder="Nombre usuario"
                  required=""
                  name="correo"
                  type="text"
                  [(ngModel)]="usuario.email"
                />

                <!--<div class="valid-feedback">Looks good!</div>-->
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label class="form-control-label"> Nombre usuario </label>
                <input
                  class="form-control"
                  placeholder="Nombre usuario"
                  name="nombre_usuario"
                  type="text"
                  [(ngModel)]="usuario.nombre_usuario"
                />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label class="form-control-label"> Contraseña </label>

                <input
                  class="form-control"
                  placeholder="Contraseña"
                  type="password"
                  name="password"
                  [(ngModel)]="usuario.password"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <h6 class="text-muted text-uppercase ls-1 mb-1">
            El rol "Usuario" siempre estará seleccionado por defecto
          </h6>
          <h2 class="h3 mb-0">Selección de roles</h2>
        </div>
        <hr class="my-3" />
        <div class="card-body">
          <div class="table-responsive">
            <table class="table align-items-center table-flush">
              <thead>
                <tr>
                  <th>Rol</th>
                  <th>Activo</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of lstRolesToSelect; index as i">
                  <td>
                    {{ item.nombre }}
                  </td>
                  <td>
                    <label class="custom-toggle mr-1">
                      <input
                        type="checkbox"
                        [checked]="item.activo == true ? 'checked' : ''"
                        (change)="changeActiveRol(i)"
                        [disabled]="item.nombre == 'Usuario'"
                      />

                      <span class="custom-toggle-slider rounded-circle"> </span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
