<div class="header  pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a> Facturas </a>
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!--<a
            class="btn btn-sm btn-neutral"
            routerLink="/simex/solicitudes/nueva-solicitud"
            style="cursor: pointer"
          >
            Agregar
          </a>

          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-lg-6">
              <h6 class="text-muted text-uppercase ls-1 mb-1">
                Administración
              </h6>
              <h2 class="h3 mb-0">Facturación de solicitudes</h2>
            </div>
            <div class="col-lg-6 text-right">
              <button class="btn btn-link" (click)="findAll()">
                <i class="fa fa-recycle" aria-hidden="true"></i>
                Refrescar
              </button>
            </div>
          </div>
        </div>


        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th scope="col">Folio</th>
                <th scope="col">Cliente</th>
                <!--<th scope="col">Estatus</th>-->
                <th scope="col">Fecha creación</th>
                <th scope="col" style="text-align: center">Cotización</th>
                <th scope="col" style="text-align: center">
                  Cotización firmada
                </th>
                <!--
                <th scope="col">Factura</th>
                <th scope="col">Comprobante de pago</th>
                -->
                <th scope="col">Acciones</th>
              </tr>
            </thead>

            <tbody class="list">
              <tr *ngFor="let item of solicitudes; index as i"
              [ngStyle]="{'background-color': item.cotizacion_aceptada?'rgb(99 238 99)':'white'}">
                <td scope="row">
                  <span class="name mb-0 text-sm">
                    <strong>
                      {{ item.folio }}
                    </strong>
                  </span>
                </td>
                <td>{{ item.cliente.razon_social }}</td>
                <!--
                <td>
                  <span class="badge badge-dot mr-4">
                    <i
                      [class]="
                        getEstatusClass(item.estatus_solicitud.clave) +
                        ' bg-dot'
                      "
                    >
                    </i>
                    <span class="status">
                      {{ item.estatus_solicitud.nombre }}
                    </span>
                  </span>
                </td>
                -->
                <td>{{ item.fecha_creacion }}</td>
                <td>
                  <div
                    *ngIf="item.cotizacion == null"
                    style="text-align: center"
                  >
                    <input
                      #filecotizacion
                      type="file"
                      class="form-control"
                      name="file-cotizacion"
                      id="file-cotizacion"
                      (change)="uploadCotizacion($event, item.folio)"
                      hidden
                    />
                    <button
                      class="btn btn-secondary"
                      (click)="filecotizacion.click()"
                    >
                      Subir cotización &nbsp;
                      <i class="fas fa-file-upload"> </i>
                    </button>

                    <!--
                    <button
                      class="btn btn-link"
                      (click)="addCotizacion(item.folio)"
                    >
                      Agregar cotización
                    </button>
                    <input class="form-control" currencyMask [(ngModel)]="value" />-->
                  </div>
                  <div
                    style="text-align: center"
                    *ngIf="item.cotizacion != null"
                  >
                    <div class="input-group" *ngIf="item.cotizacion">
                      <input
                        id="nombre-plantilla"
                        type="text"
                        class="form-control"
                        disabled
                        [value]="item.cotizacion.file_name"
                      />
                      <button
                        class="btn btn-success"
                        type="button"
                        (click)="downloadCotizacion(item.cotizacion)"
                      >
                        <i class="fas fa-file-download"></i>
                      </button>
                      <button
                        class="btn btn-danger"
                        type="button"
                        (click)="deleteCotizacionAlert(item.folio)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                    <!--
                    <br />
                    <h4>
                      <strong
                        *ngIf="
                          item.cotizacion_aceptada == null &&
                          item.cotizacion_rechazada == null
                        "
                        >Sin atender</strong
                      >
                      <strong
                        *ngIf="item.cotizacion_aceptada"
                        style="color: rgb(31, 163, 31)"
                        >Cotización aceptada</strong
                      >
                      <strong
                        *ngIf="item.cotizacion_rechazada"
                        style="color: rgb(182, 28, 28)"
                        >Cotización rechazada</strong
                      >
                    </h4>
                    -->
                  </div>
                </td>
                <td>
                  <div *ngIf="item.cotizacion != null">
                    <span *ngIf="item.cotizacion_firmada == null">
                      <input
                        #filecotizacionfirmada
                        type="file"
                        class="form-control"
                        name="file-cotizacion"
                        id="file-cotizacion"
                        (change)="uploadCotizacionFirmada($event, item.folio)"
                        hidden
                      />
                      <button
                        class="btn btn-secondary"
                        (click)="filecotizacionfirmada.click()"
                      >
                        Subir cotización firmada &nbsp;
                        <i class="fas fa-file-upload"> </i>
                      </button>
                    </span>

                    <span
                      class="input-group"
                      *ngIf="item.cotizacion_firmada != null"
                    >
                      <input
                        id="nombre-plantilla"
                        type="text"
                        class="form-control"
                        disabled
                        [value]="item.cotizacion_firmada.file_name"
                      />
                      <button
                        class="btn btn-success"
                        type="button"
                        (click)="
                          downloadCotizacionFirmada(item.cotizacion_firmada)
                        "
                      >
                        <i class="fas fa-file-download"></i>
                      </button>
                      <button
                        class="btn btn-danger"
                        type="button"
                        (click)="deleteCotizacionFirmadaAlert(item.folio)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </span>
                  </div>
                </td>
                <!--
                <td>
                  <div *ngIf="item.factura == null && item.cotizacion_firmada != null">
                    <input
                      #filefactura
                      type="file"
                      class="form-control"
                      name="file-cotizacion"
                      id="file-cotizacion"
                      (change)="uploadFactura($event, item.folio)"
                      hidden
                    />
                    <button
                      class="btn btn-secondary"
                      (click)="filefactura.click()"
                    >
                      Subir factura &nbsp;
                      <i class="fas fa-file-upload"> </i>
                    </button>
                  </div>
                  <div *ngIf="item.factura != null && item.cotizacion_firmada != null" class="input-group">
                    <input
                      id="nombre-plantilla"
                      type="text"
                      class="form-control"
                      disabled
                      [value]="item.factura.file_name"
                    />
                    <button
                      class="btn btn-success"
                      type="button"
                      (click)="downloadFactura(item.factura)"
                    >
                      <i class="fas fa-file-download"></i>
                    </button>
                    <button
                      class="btn btn-danger"
                      type="button"
                      (click)="deleteFacturaAlert(item.folio)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td>
                <td>
                  <div
                    class="input-group"
                    *ngIf="item.comprobante_pago !== null && item.cotizacion_firmada != null"
                  >
                    <input
                      id="nombre-plantilla"
                      type="text"
                      class="form-control"
                      disabled
                      [value]="item.comprobante_pago.file_name"
                    />
                    <button
                      class="btn btn-success"
                      type="button"
                      (click)="downloadFichaPago(item.comprobante_pago)"
                    >
                      <i class="fas fa-file-download"></i>
                    </button>
                    <button
                      class="btn btn-danger"
                      type="button"
                      (click)="deleteFichaPagoAlert(item.folio)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <h4 *ngIf="item.comprobante_pago === null && item.cotizacion_firmada != null">
                    <input
                      #filefichaPago
                      type="file"
                      class="form-control"
                      name="file-ficha-pago"
                      id="file-ficha-pago"
                      (change)="uploadFichaPago($event, item.folio)"
                      hidden
                    />
                    <button
                      class="btn btn-secondary"
                      (click)="filefichaPago.click()"
                    >
                      Subir comprobante de pago &nbsp;
                      <i class="fas fa-file-upload"> </i>
                    </button>
                  </h4>
                </td>
                -->
                <td>
                  <!---->
                  <div class="text-right">
                    <div class="dropdown no-caret" dropdown [dropup]="'true'">
                      <a
                        aria-controls="dropdown-basic"
                        class="btn btn-sm btn-icon-only text-dark dropdown-toggle"
                        dropdownToggle
                        href="javascript:void(0)"
                        role="button"
                      >
                        <i class="fas fa-ellipsis-v"> </i>
                      </a>

                      <div
                        class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                        *dropdownMenu
                      >
                        <a
                          href="javascript:void(0)"
                          class="dropdown-item"
                          (click)="clickOpenModalNotificaciones(item)"
                        >
                          Enviar notificación
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-item"
                          *ngIf="item.cotizacion_aceptada && !item.aprobado"
                          (click)="aprobarSolicitud(item.folio)"
                        >
                          Enviar a operaciones
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-item"
                          (click)="goToDetalles(item.folio)"
                        >
                          Ver detalles
                        </a>
                        <!--
                        <a
                          href="javascript:void(0)"
                          class="dropdown-item"
                          *ngIf="item.comprobante_pago !== null && !item.pagado"
                          (click)="aprobarPago(item.folio)"
                        >
                          Aprobar pago
                        </a>
                        -->
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer py-4">
          <nav aria-label="...">
            <pagination
              [totalItems]="totalElements"
              class="justify-content-end mb-0"
              [customPreviousTemplate]="prevTemplate"
              [customNextTemplate]="nextTemplate"
              [itemsPerPage]="size"
              [(ngModel)]="page"
              (pageChanged)="pageChanged($event)"
              [maxSize]="5"
            >
            </pagination>

            <ng-template #nextTemplate let-currentPage="currentPage">
              <ng-container *ngIf="!disabled">
                <i class="fas fa-angle-right"></i>
              </ng-container>
            </ng-template>

            <ng-template #prevTemplate let-currentPage="currentPage">
              <ng-container *ngIf="!disabled">
                <i class="fas fa-angle-left"></i>
              </ng-container>
            </ng-template>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
<!--             MODALES              -->
<div>
  <button
    id="btn-modal-servicios"
    data-target="#modal-default"
    data-toggle="modal"
    type="button"
    (click)="openDefaultModal(modalDefault)"
    hidden
  >
    Default
  </button>

  <ng-template #modalDefault>
    <div class="modal-header">
      <h6 class="modal-title" id="modal-title-default">
        {{ solicitudSelected.folio }}
      </h6>

      <button
        aria-label="Close"
        class="close"
        data-dismiss="modal"
        type="button"
        (click)="defaultModal.hide()"
      >
        <span aria-hidden="true"> × </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="table-responsive">
        <table class="table align-items-center table-flush">
          <thead class="thead-light">
            <th>Sección</th>
            <th>Servicio</th>
            <th>Estatus</th>
          </thead>
          <tbody>
            <tr *ngFor="let item of solicitudSelected.servicios; index as e">
              <td>{{ item.seccion.nombre }}</td>
              <td>{{ item.servicio.nombre }}</td>
              <td>
                <span
                  class="badge badge-dot mr-4"
                  *ngIf="item.estatus == 'Pendiente'"
                >
                  <i class="bg-warning"></i>
                  <span class="status"> Pendiente </span>
                </span>
                <span
                  class="badge badge-dot mr-4"
                  *ngIf="item.estatus == 'En proceso'"
                >
                  <i class="bg-success"></i>
                  <span class="status"> En proceso </span>
                </span>
                <span
                  class="badge badge-dot mr-4"
                  *ngIf="item.estatus == 'Finalizado'"
                >
                  <i class="bg-danger"></i>
                  <span class="status"> Finalizado </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal-footer">
      <button
        class="btn btn-link ml-auto"
        data-dismiss="modal"
        type="button"
        (click)="defaultModal.hide()"
      >
        Cerrar
      </button>
    </div>
  </ng-template>

  <button
    id="btn-modal-notificaciones"
    data-target="#modal-notificaciones"
    data-toggle="modal"
    type="button"
    (click)="openNotificacionesModal(modalNotificaciones)"
    hidden
  >
    modal notificaciones
  </button>

  <ng-template #modalNotificaciones>
    <div class="modal-header">
      <h6 class="modal-title" id="modal-title-default">Notificaciones</h6>

      <button
        aria-label="Close"
        class="close"
        data-dismiss="modal"
        type="button"
        (click)="notificacionesModal.hide()"
      >
        <span aria-hidden="true"> × </span>
      </button>
    </div>

    <div class="modal-body">
      <h3>
        Esta notificación se enviará al cliente "{{
          solicitudSelected.cliente.razon_social
        }}"
      </h3>
      <br />
      <div class="form-group">
        <label>Tipo de notificación</label>
        <select
          name="tiponotificacion"
          class="form-control"
          [(ngModel)]="tipoNotificacion"
        >
          <option
            *ngIf="tipoNotificacion == 'seleccionar'"
            [value]="'seleccionar'"
          >
            Seleccionar...
          </option>
          <option
            *ngIf="solicitudSelected.cotizacion_aceptada"
            [value]="'pendiente_pago'"
          >
            Pendiente de pago
          </option>
          <option [value]="'facturas'">Enviar facturas</option>
          <option [value]="'personalizado'">Personalizado</option>
        </select>
      </div>
      <div *ngIf="tipoNotificacion == 'personalizado'">
        <hr />
        <div class="form-group">
          <label>Titulo de notificación</label>
          <input
            name="tituloNotificacion"
            type="text"
            class="form-control"
            [(ngModel)]="tituloNotificacion"
          />
        </div>
        <div class="form-group">
          <label>Mensaje</label>
          <textarea
            name="mensajeNotificacion"
            id=""
            cols="30"
            rows="10"
            class="form-control"
            [(ngModel)]="mensajeNotificacion"
          ></textarea>
        </div>
      </div>
      <div *ngIf="tipoNotificacion == 'facturas'">
        <hr />
        <div class="form-group">
          <label>Titulo de notificación</label>
          <input
            name="tituloNotificacion"
            type="text"
            class="form-control"
            [(ngModel)]="tituloNotificacion"
          />
        </div>
        <div class="form-group">
          <label>Mensaje</label>
          <textarea
            name="mensajeNotificacion"
            id=""
            cols="30"
            rows="10"
            class="form-control"
            [(ngModel)]="mensajeNotificacion"
          ></textarea>
        </div>
        <h4>Servicios</h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div class=" custom-control custom-checkbox mb-3">
                    <input
                      class=" custom-control-input"
                      id="customCheck-all"
                      type="checkbox"
                      [checked]="serviciostsAll"
                      (change)="changeCheckedAll()"
                    />

                    <label class=" custom-control-label" for="customCheck-all">
                    </label>
                  </div>
                </th>
                <th>Sección</th>
                <th>Servicio</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of serviciosts, index as i">
                <td>
                  <div class=" custom-control custom-checkbox mb-3">
                    <input
                      class=" custom-control-input"
                      id="customCheck-{{i}}"
                      type="checkbox"
                      [checked]="item.checked"
                      (change)="changeCheckedServicio(i)"
                    />

                    <label class=" custom-control-label" for="customCheck-{{i}}">
                    </label>
                  </div>
                </td>
                <td>{{item.seccion.nombre}}</td>
                <td>{{item.servicio.nombre}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>Documentos</h4>
        <div class="form-group">
          <input
          #filefacturaservicio
          type="file"
          class="form-control"
          name="file-factura-servicio"
          id="file-factura-servicio"
          (change)="cargarDocumentFacturaServicio($event)"
          hidden
        />
        <button
          class="btn btn-secondary"
          (click)="filefacturaservicio.click()"
        >
          Cargar documento &nbsp;
          <i class="fas fa-file-upload"> </i>
        </button>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Nombre documento</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of docsFacturasServicios, index as i">
                <td>{{item.filename}}</td>
                <td>
                  <button
                        class="btn btn-danger"
                        type="button"
                        (click)="eliminarDocumentoFacturaServicio(i)"
                      >
                        <i class="fas fa-trash"></i>
                        Eliminar
                      </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button
        class="btn btn-success"
        data-dismiss="modal"
        type="button"
        (click)="enviarNotificacion()"
        [disabled]="btnEnviarNotificacionDisabled()"
      >
        Enviar notificación
      </button>
      <button
        class="btn btn-link"
        data-dismiss="modal"
        type="button"
        (click)="notificacionesModal.hide()"
      >
        Cerrar
      </button>
    </div>
  </ng-template>

  <button
    id="btn-modal-historico-facturas"
    data-target="#modal-historico-facturas"
    data-toggle="modal"
    type="button"
    (click)="openHistoricoFacturasModal(modalHistoricoFacturas)"
    hidden
  >
    modal facturas
  </button>

<ng-template #modalHistoricoFacturas>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-title-default">Historico facturas -  {{ solicitudSelected.folio }}</h6>

    <button
      aria-label="Close"
      class="close"
      data-dismiss="modal"
      type="button"
      (click)="historicoFacturasModal.hide()"
    >
      <span aria-hidden="true"> × </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Servicios</th>
            <th>Documentos</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of lstHistoricoFacturas, index as i">
            <td>{{item.fecha}}</td>
            <td>
              <div *ngFor="let ser of item.servicios_factura_mensual">
                <span>{{ser.servicio}}</span>
              </div>
            </td>
            <td>
              <div class="row mt-2" *ngFor="let doc of item.documentos_factura_mensual">
                <span class="input-group">
                  <input
                    id="nombre-plantilla"
                    type="text"
                    class="form-control"
                    disabled
                    [value]="doc.file_name"
                  />
                  <button
                    class="btn btn-success"
                    type="button"
                    (click)="
                    downloadFacturaMensual(doc)
                    "
                  >
                    <i class="fas fa-file-download"></i>
                  </button>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-link"
      data-dismiss="modal"
      type="button"
      (click)="historicoFacturasModal.hide()"
    >
      Cerrar
    </button>
  </div>

</ng-template>

</div>
<style>
  .bg-dot {
    height: 1rem;
    width: 1rem;
  }

  .bg-none {
    background-color: black;
  }
</style>
