import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { Solicitud } from "src/app/models/Solicitud";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import swal from "sweetalert2";
import * as $ from 'jquery';
import { FacturaService } from "../../services/facturas.service";
import { ComprobantePagoSolicitud } from "src/app/models/ComprobantePagoSolicitud";
import { DocumentoCotizacion } from "src/app/models/DocumentosCotizacion";
import { HistoricoFacturas } from "src/app/models/HistoricoFacturas";
import { DocumentoFacturaMensual } from "src/app/models/DocumentoFacturaMensual";
@Component({
  selector: "app-facturas",
  templateUrl: "facturas.component.html",
  styleUrls:['facturas.component.css']
})
export class FacturasComponent implements OnInit {

  solicitudes:Solicitud[] = []
  solicitudSelected:Solicitud = new Solicitud()
  page:number = 1
  size:number = 5
  totalElements:number = 0
  filtro = {
    folio: '',
    cliente: '',
    estatus: '',
    cantidad: '',
    fecha: ''
  };

  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered modal-xl"
  };
  notificacionesModal: BsModalRef;
  settingsNotificacionModal = {
    keyboard: true,
    class: "modal-dialog-centered modal-lg"
  };

  historicoFacturasModal: BsModalRef;
  settingsHistoricoFacturasModal = {
    keyboard: true,
    class: "modal-dialog-centered modal-xl"
  };

  tipoNotificacion:string = "seleccionar"
  tituloNotificacion:string = ""
  mensajeNotificacion:string = ""

  serviciosts: any[] = []
  serviciostsAll:boolean = false
  docsFacturasServicios: any[] =[]

  lstHistoricoFacturas: HistoricoFacturas[] = []
  constructor(private modalService: BsModalService, private router: Router, private facturaService: FacturaService) {}

  ngOnInit() {

    var that = this
    $(document).ready(function() {
      // Seleccionamos el input por su ID
      var $miInput = $("#input-buscar-solicitud");

      // Agregamos el evento keypress al input
      $miInput.on("keypress", function(event) {
        // Comprobamos si la tecla presionada es "Enter" (código 13)
        if (event.which === 13) {
          // Aquí puedes realizar la acción que desees cuando se presione "Enter"
          that.searchData()
          // Evitamos el comportamiento por defecto del Enter en un formulario
          event.preventDefault();
        }
      });
    });

    this.findAll()
  }

  findAll(){
    const formData = new FormData()
    //formData.append("filtro","")
    formData.append("page",String(this.page-1))
    formData.append("size",String(this.size))
    this.facturaService.findAll(formData).subscribe(success=>{
      this.solicitudes = success["content"]
      this.totalElements = success["totalElements"]
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  sweetAlert(icon,title:string,button:string,text:string){
    swal.fire({
      title: title,
      text: "",
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  getEstatusClass(estatus:string):string{
    if(estatus=="PENDIENTE"){
      return "bg-warning"
    }else if(estatus=="SOLICITUD DE INFORMACION"){
      return "bg-info"
    }else if (estatus=="EN REVISION") {
      return "bg-warning"
    }else if (estatus=="EN PROCESO") {
      return "bg-success"
    }else if (estatus=="FINALIZADO") {
      return "bg-danger"
    }else if(estatus=="SIN APROBAR"){
      return "bg-none"
    }
  }

  goToSolicitud(id:number){
    this.router.navigate([`/simex/solicitudes/solicitud/${id}`]);
  }

  searchData(){
    let search = String($("#input-buscar-solicitud").val())
    /*
    this.temp = this.solicitudes.filter((item) => {
      // Modifica esta lógica según tus necesidades
      return (
        item.folio.toUpperCase().includes(search.toUpperCase()) ||
        item.cliente.razon_social.toUpperCase().includes(search.toUpperCase()) ||
        item.estatus_solicitud.nombre.toUpperCase().includes(search.toUpperCase()) ||
        item.servicios.length.toString().includes(search)
      );
    });
    */
  }

  addCotizacion(folio:string){
    var that = this
    swal.fire({
      title: 'Agregar cotización',
      html: '<input type="number" id="inputCotizacion" class="swal2-input">',
      showCancelButton: true,
      confirmButtonText: 'Agregar',
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        const campoValor = document.getElementById('inputCotizacion')["value"];
        return campoValor;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const valorIngresado = result.value;
        if(valorIngresado===""){
          swal.fire('Debe agregar una cotización');
          that.addCotizacion(folio)
        }else{
          that.addCotizacionService(valorIngresado, folio)
        }
      }
    });
  }

  addCotizacionService(cotizacion:string, folio:string){
    const precio = Number(cotizacion).toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2, // Mínimo 2 decimales
      maximumFractionDigits: 2, // Máximo 2 decimales
    });
    const formData = new FormData()
    formData.append("folio",folio)
    formData.append("cotizacion",precio)
    this.facturaService.addCotizacion(formData).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!","Se hizo la cotización correctamente","success")
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  aprobarSolicitud(folio:string){
    var that = this
    swal.fire({
      title: `¿Estas seguro de mandar la solicitud con folio ${folio} a operaciones?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        that.aprobarSolicitudService(folio)
      }
    })
  }

  aprobarSolicitudService(folio:string){
    const formData = new FormData()
    formData.append("folio", folio)
    this.facturaService.aprobarSolicitud(formData).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se mando la solicitud con el folio ${folio} a operaciones`,"success")
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  aprobarPago(folio:string){
    var that = this
    swal.fire({
      title: `¿Estas seguro de aprobar el pago de la solicitud con folio ${folio}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        that.aprobarPagoService(folio)
      }
    })
  }

  aprobarPagoService(folio:string){
    const formData = new FormData()
    formData.append("folio", folio)
    formData.append("tipoNotificacion","personalizado")
    formData.append("titulo","Pago exitoso")
    formData.append("mensaje","Su pago ha sido aprobado.")
    this.facturaService.aprobarPago(formData).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se aprobó el pago de la solicitud con folio ${folio} correctamente`,"success")
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  uploadFichaPago($event:any, folio:string){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("folio", folio)
    this.facturaService.uploadFichaPago(formData).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se cargo el comprobante de pago de la solicitud con folio ${folio}.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadFichaPago(item:ComprobantePagoSolicitud){
    this.facturaService.downloadFichaPago(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  deleteFichaPagoAlert(folio:string){
    var that = this
    swal.fire({
      title: `¿Estas seguro que deseas eliminar el comprobante de pago de la solicitud con folio "${folio}"?`,
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
      icon:"question"
    }).then((result) => {
      if (result.isConfirmed) {
        that.deleteFichaPago(folio)
      }
    })
  }

  deleteFichaPago(folio:string){
    this.facturaService.deleteFichaPago(folio).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se eliminó el comprobante de pago de la solicitud con folio ${folio} correctamente.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  pageChanged($event){
    this.page = $event["page"]
    this.findAll()
  }

  openDefaultModal(modalDefault: TemplateRef<any>) {
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  openNotificacionesModal(modalNotificaciones: TemplateRef<any>) {
    this.notificacionesModal = this.modalService.show(modalNotificaciones, this.settingsNotificacionModal);
  }

  openHistoricoFacturasModal(modalHistoricoFacturas: TemplateRef<any>) {
    this.historicoFacturasModal = this.modalService.show(modalHistoricoFacturas, this.settingsHistoricoFacturasModal);
  }

  clickOpenModalServicios(item){
    this.solicitudSelected = item
    $("#btn-modal-servicios").click();
  }

  clickOpenModalNotificaciones(item){
    this.solicitudSelected = item
    this.tipoNotificacion = "seleccionar"
    this.tituloNotificacion = ""
    this.mensajeNotificacion = ""
    this.docsFacturasServicios = []
    this.serviciostsAll = false
    this.serviciosts = item.servicios
    for(let i = 0; i < this.serviciosts.length; i++){
      this.serviciosts["checked"] = false
    }
    $("#btn-modal-notificaciones").click();
  }

  clickOpenModalHistoricoFacturas(item){
    this.solicitudSelected = item;
    $("#btn-modal-historico-facturas").click();
    this.findHistoricoFacturaByFolio(item.folio);
  }

  enviarNotificacion(){
    this.notificacionesModal.hide()
    if(this.tipoNotificacion == 'facturas'){
      this.enviarFacturaMensual()
    }else{
      const formData = new FormData()
      formData.append("folio",this.solicitudSelected.folio)
      formData.append("tipoNotificacion",this.tipoNotificacion)
      formData.append("titulo",this.tituloNotificacion)
      formData.append("mensaje",this.mensajeNotificacion)
      this.facturaService.enviarNotificacion(formData).subscribe(success=>{
        this.swalNotification("Excelente!!",`Se envió la notificación al cliente ${this.solicitudSelected.cliente.razon_social} con éxito.`,"success")
      },error=>{
        console.log(error)
        this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
      })
    }

  }

  btnEnviarNotificacionDisabled():boolean{
    if(this.tipoNotificacion=="pendiente_pago"){
      return false
    }else if(this.tipoNotificacion=="personalizado"){
      if(this.tituloNotificacion.trim()=="" || this.mensajeNotificacion.trim() == ""){
        return true
      }else{
        return false
      }
    }else{
      true
    }
    return true
  }

  reactivarSolicitudAlert(folio:string){
    var that = this
    swal.fire({
      title: `¿Estas seguro que deseas reactivar solicitud con folio "${folio}"?`,
      showDenyButton: true,
      confirmButtonText: 'Reactivar',
      denyButtonText: `Cancelar`,
      icon:"question"
    }).then((result) => {
      if (result.isConfirmed) {
        that.reactivarSolicitud(folio)
      }
    })
  }

  reactivarSolicitud(folio:string){
    const formData = new FormData()
    formData.append("folio", folio)
    this.facturaService.reactivarSolicitud(formData).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se reactivó la solicitud con folio ${folio}.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  uploadCotizacion($event:any, folio:string){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("folio", folio)
    this.facturaService.uploadCotizacion(formData).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se cargo la cotización con folio ${folio}.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadCotizacion(item:DocumentoCotizacion){
    this.facturaService.downloadCotizacion(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  deleteCotizacionAlert(folio:string){
    var that = this
    swal.fire({
      title: `¿Estas seguro que deseas eliminar la cotización de la solicitud con folio "${folio}"?`,
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
      icon:"question"
    }).then((result) => {
      if (result.isConfirmed) {
        that.deleteCotizacion(folio)
      }
    })
  }

  deleteCotizacion(folio:string){
    this.facturaService.deleteCotizacion(folio).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se eliminó la cotización con folio ${folio} correctamente.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }


  uploadCotizacionFirmada($event:any, folio:string){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("folio", folio)
    this.facturaService.uploadCotizacionFirmada(formData).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se cargo la cotización con folio ${folio}.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadCotizacionFirmada(item:DocumentoCotizacion){
    this.facturaService.downloadCotizacionFirmada(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  deleteCotizacionFirmadaAlert(folio:string){
    var that = this
    swal.fire({
      title: `¿Estas seguro que deseas eliminar la cotización firmada de la solicitud con folio "${folio}"?`,
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
      icon:"question"
    }).then((result) => {
      if (result.isConfirmed) {
        that.deleteCotizacionFirmada(folio)
      }
    })
  }

  deleteCotizacionFirmada(folio:string){
    this.facturaService.deleteCotizacionFirmada(folio).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se eliminó la cotización con folio ${folio} correctamente.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  uploadFactura($event:any, folio:string){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("folio", folio)
    this.facturaService.uploadFactura(formData).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se cargo la factura de solicitud con folio ${folio}.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadFactura(item:DocumentoCotizacion){
    this.facturaService.downloadFactura(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  deleteFacturaAlert(folio:string){
    var that = this
    swal.fire({
      title: `¿Estas seguro que deseas eliminar la factura de la solicitud con folio "${folio}"?`,
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
      icon:"question"
    }).then((result) => {
      if (result.isConfirmed) {
        that.deleteFactura(folio)
      }
    })
  }

  deleteFactura(folio:string){
    this.facturaService.deleteFactura(folio).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se eliminó la factura de solicitud con folio ${folio} correctamente.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  changeCheckedServicio(i:number){
    let checked = !this.serviciosts[i]["checked"]
    this.serviciosts[i]["checked"] = checked
    if(checked){
      this.serviciostsAll = true
    }
  }

  changeCheckedAll(){
    let checked = !this.serviciostsAll
    this.serviciostsAll = checked
    for(let i = 0; i < this.serviciosts.length; i++){
      this.serviciosts[i]["checked"] = checked
    }
  }

  cargarDocumentFacturaServicio($event){
    let file = $event.target.files[0]
    this.docsFacturasServicios.push(
      {
        "filename":file.name,
        "file":file
      }
    )
    $("#file-factura-servicio").val("")
  }

  eliminarDocumentoFacturaServicio(i:number){
    this.docsFacturasServicios.splice(i,1);
  }

  enviarFacturaMensual(){
    this.facturaService.enviarFacturaMensual(this.getFormDataFacturaMensual()).subscribe(success=>{
      this.findAll()
      this.swalNotification("Excelente!!",`Se envió la facturación correctamente.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  getFormDataFacturaMensual(): FormData{
    const formData = new FormData
    //let documentos:any = []
    for(let doc of this.docsFacturasServicios){
      //console.log(doc["file"])
      //documentos.push(doc["file"])
      formData.append('documentos', doc.file, doc.file.name);
    }
    let servicios:any = []
    for(let ser of this.serviciosts){
      if(ser["checked"]){
        servicios.push({
          "seccion":ser.seccion.nombre,
          "servicio":ser.servicio.nombre
        })
      }
    }
    //formData.append("documentos",documentos)
    formData.append("servicios", JSON.stringify(servicios))
    formData.append("folio", this.solicitudSelected.folio)
    formData.append("titulo", this.tituloNotificacion)
    formData.append("mensaje", this.mensajeNotificacion)
    return formData
  }

  findHistoricoFacturaByFolio(folio:string){
    this.facturaService.findHistoricoFacturaByFolio(folio).subscribe(success=>{
      console.log(success)
      this.lstHistoricoFacturas = success
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadFacturaMensual(item:DocumentoFacturaMensual){
    this.facturaService.downloadFacturaMensual(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  goToDetalles(folio:string){
    this.router.navigate([`/simex/facturas-detalles/${folio}`]);
  }
}
