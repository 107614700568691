<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> Secciones </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-neutral"
            routerLink="/simex/secciones-servicios/nuevo-seccion-servicio"
            style="cursor: pointer"
            *ngIf="crearPermission"
          >
            Agregar
          </a>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>

          <h2 class="h3 mb-0">Secciones y Servicios</h2>
        </div>
        <!--
        <ngx-datatable
        class="bootstrap selection-cell"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [limit]="10"
        [rows]="lstSecciones"
      >
        <ngx-datatable-column name="Seccion" prop="nombre"></ngx-datatable-column>
        <ngx-datatable-column name="Fecha creación" prop="fecha_creacion"></ngx-datatable-column>
        <ngx-datatable-column name="Fecha actualización" prop="fecha_actualizacion"></ngx-datatable-column>
        <ngx-datatable-column name="Acciones">
          <ng-template ngx-datatable-cell-template let-row="row">

            <button class="btn btn-primary"
            data-target="#modal-default"
            data-toggle="modal"
            (click)="openDefaultModal(modalDefault, row)">
              Ver &nbsp;
              <i class="fas fa-eye"> </i>
            </button>


            <ng-template #modalDefault>
              <div class="modal-header">
                <h6 class="modal-title" id="modal-title-default">
                  {{ seccionSelected.nombre }}
                </h6>

                <button
                  aria-label="Close"
                  class="close"
                  data-dismiss="modal"
                  type="button"
                  (click)="defaultModal.hide()"
                >
                  <span aria-hidden="true"> × </span>
                </button>
              </div>

              <div class="modal-body">
                <div class="table-responsive">
                  <table class="table align-items-center table-flush">
                    <thead class="thead-light">
                      <th>Posición</th>
                      <th>Servicio</th>
                      <th>Estatus</th>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let serv of seccionSelected.servicios;
                          index as e
                        "
                      >
                        <td>{{ e + 1 }}</td>
                        <td>{{ serv.nombre }}</td>
                        <td [ngStyle]="{ 'color': serv.eliminado?'red':'green'}">

                          <strong>{{serv.eliminado?'INACTIVO':'ACTIVO'}}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  class="btn btn-link ml-auto"
                  data-dismiss="modal"
                  type="button"
                  (click)="defaultModal.hide()"
                >
                  Cerrar
                </button>
              </div>
            </ng-template>

            <button class="btn btn-success" (click)="goToEdit(row.id)" *ngIf="editarPermission">
              Editar &nbsp;
              <i class="fas fa-user-edit"> </i>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
        -->

      <div class="table-responsive">
        <table class="table align-items-center table-flush">
          <thead class="thead-light">
            <tr>
              <th scope="col">Seccion</th>
              <th scope="col">Fecha creación</th>
              <th scope="col">Fecha actualización</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>

          <tbody class="list">
            <tr *ngFor="let item of lstSecciones; index as i">
              <td scope="row">{{ item.nombre }}</td>
              <td>{{ item.fecha_creacion }}</td>
              <td>{{ item.fecha_actualizacion }}</td>
              <td>
                <button class="btn btn-primary"
                data-target="#modal-default"
                data-toggle="modal"
                (click)="openDefaultModal(modalDefault, item)">
                  Ver &nbsp;
                  <i class="fas fa-eye"> </i>
                </button>


                <ng-template #modalDefault>
                  <div class="modal-header">
                    <h6 class="modal-title" id="modal-title-default">
                      {{ seccionSelected.nombre }}
                    </h6>

                    <button
                      aria-label="Close"
                      class="close"
                      data-dismiss="modal"
                      type="button"
                      (click)="defaultModal.hide()"
                    >
                      <span aria-hidden="true"> × </span>
                    </button>
                  </div>

                  <div class="modal-body">
                    <div class="table-responsive">
                      <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                          <th>Posición</th>
                          <th>Servicio</th>
                          <th>Estatus</th>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let serv of seccionSelected.servicios;
                              index as e
                            "
                          >
                            <td>{{ e + 1 }}</td>
                            <td>{{ serv.nombre }}</td>
                            <td [ngStyle]="{ 'color': serv.eliminado?'red':'green'}">

                              <strong>{{serv.eliminado?'INACTIVO':'ACTIVO'}}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      class="btn btn-link ml-auto"
                      data-dismiss="modal"
                      type="button"
                      (click)="defaultModal.hide()"
                    >
                      Cerrar
                    </button>
                  </div>
                </ng-template>

                <button class="btn btn-success" (click)="goToEdit(item.id)" *ngIf="editarPermission">
                  Editar &nbsp;
                  <i class="fas fa-user-edit"> </i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer py-4">
        <nav aria-label="...">
          <pagination
            [totalItems]="totalElements"
            class="justify-content-end mb-0"
            [customPreviousTemplate]="prevTemplate"
            [customNextTemplate]="nextTemplate"
            [itemsPerPage]="size"
            [(ngModel)]="page"
            (pageChanged)="pageChanged($event)"
            [maxSize]="5"
          >
          </pagination>

          <ng-template #nextTemplate let-currentPage="currentPage">
            <ng-container *ngIf="!disabled">
              <i class="fas fa-angle-right"></i>
            </ng-container>
          </ng-template>

          <ng-template #prevTemplate let-currentPage="currentPage">
            <ng-container *ngIf="!disabled">
              <i class="fas fa-angle-left"></i>
            </ng-container>
          </ng-template>
        </nav>
      </div>
      </div>
    </div>
  </div>
</div>
