import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Authenticate } from 'src/app/models/Authenticate';
import { Usuario } from '../models/Usuario';
@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  url:string = environment.url
  constructor(private http: HttpClient) { }

  login(authenticate:Authenticate): Observable<any> {
    return this.http.post<any>(`${this.url}/session/v1/authenticate`, authenticate);
  }

  public getToken(): string {
    return localStorage.getItem("token")
    // Obtiene el token del almacenamiento local o de la cookie según tus necesidades
  }

  public isTokenExpired(): Observable<boolean>{
    return this.http.get<boolean>(`${this.url}/session/v1/isTokenExpired/${this.getToken()}`)
  }

  public logout(): void {
    localStorage.removeItem("token")
    // Elimina el token del almacenamiento local o de la cookie según tus necesidades
  }

  public findAll(): Observable<any>{
    return this.http.get<any>(`${this.url}/user/v1/findAll`);
  }

  public findByPage(formData:FormData): Observable<any>{
    const params = this.formDataToHttpParams(formData);
    return this.http.get<any>(`${this.url}/user/v1/findByPage`, { params });
  }

  public save(usuario:Usuario): Observable<any>{
    return this.http.post(`${this.url}/user/v1/save`, usuario);
  }

  public findById(id:number): Observable<any>{
    return this.http.get<any>(`${this.url}/user/v1/findById/${id}`);
  }

  public update(usuario:Usuario): Observable<any>{
    return this.http.put(`${this.url}/user/v1/update`, usuario);
  }

  private formDataToHttpParams(formData: FormData): HttpParams {
    let httpParams = new HttpParams();
    formData.forEach((value, key) => {
      httpParams = httpParams.append(key, String(value));
    });
    return httpParams;
  }

}
