import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { RolService } from "src/app/services/rol.service";
import { Rol } from "src/app/models/Rol";
import { Modulo } from "src/app/models/Modulo";
import { ModulosRolPermisos } from "src/app/models/ModulosRolPermisos";
import swal from "sweetalert2";
import { PermisosService } from "src/app/services/permisos.service";
@Component({
  selector: "app-nuevo-rol",
  templateUrl: "nuevo-rol.component.html"
})
export class NuevoRolComponent implements OnInit {

  rol:Rol = new Rol()
  lstModulos: Modulo[] = []
  lstModuloRolPermisos: ModulosRolPermisos[] = []
  constructor( private rolService:RolService, private permisosService:PermisosService, private router: Router){}

  ngOnInit(): void {
    this.findAllModulos()
  }

  findAllModulos(){
    this.permisosService.findAllModulos().subscribe(success=>{
      this.lstModulos = success
      this.setModulosToListPermission()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  setModulosToListPermission(){
    for(let mod of this.lstModulos){
      this.lstModuloRolPermisos.push(
        new ModulosRolPermisos(mod)
      )
    }
  }

  changeActivePermission(i:number,accion:string){
    if(accion=="ver"){
      this.lstModuloRolPermisos[i].ver = !this.lstModuloRolPermisos[i].ver
      if(!this.lstModuloRolPermisos[i].ver){
        this.lstModuloRolPermisos[i].crear = false
        this.lstModuloRolPermisos[i].editar = false
        this.lstModuloRolPermisos[i].eliminar = false
      }
    }else if(accion=="crear"){
      this.lstModuloRolPermisos[i].crear = !this.lstModuloRolPermisos[i].crear
      if(this.lstModuloRolPermisos[i].crear){
        this.lstModuloRolPermisos[i].ver = true
      }
    }else if(accion=="editar"){
      this.lstModuloRolPermisos[i].editar = !this.lstModuloRolPermisos[i].editar
      if(this.lstModuloRolPermisos[i].editar){
        this.lstModuloRolPermisos[i].ver = true
      }
    }else if(accion=="eliminar"){
      this.lstModuloRolPermisos[i].eliminar = !this.lstModuloRolPermisos[i].eliminar
      if(this.lstModuloRolPermisos[i].eliminar){
        this.lstModuloRolPermisos[i].ver = true
      }
    }
    console.log(this.lstModuloRolPermisos)
  }

  saveAlert(){
    var that = this
    swal.fire({
      title: '¿Estas seguro que deseas crear este rol?',
      showDenyButton: true,
      confirmButtonText: 'Crear',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.save()
      }
    })
  }

  save(){
    for(let i = 0; i < this.lstModuloRolPermisos.length; i++){
      this.lstModuloRolPermisos[i].rol_id = this.rol
    }

    this.permisosService.save(this.lstModuloRolPermisos).subscribe(success=>{
      this.swalNotification("Excelente!!","El rol fue creado correctamente","success")
      this.router.navigate(['/simex/roles'])
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  sweetAlert(icon,title:string,button:string,text:string){
    swal.fire({
      title: title,
      text: "",
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }
}
