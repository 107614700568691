import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Authenticate } from 'src/app/models/Authenticate';
@Injectable({
  providedIn: 'root'
})
export class RolService {
  url:string = environment.url
  constructor(private http: HttpClient) { }

  findAll():Observable<any>{
    return this.http.get(`${this.url}/rol/v1/findAll`);
  }

  findById(id:number):Observable<any>{
    return this.http.get(`${this.url}/rol/v1/findById/${id}`)
  }
}
