<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a routerLink="/simex/solicitudes"> Solicitudes </a>
              </li>
              <li class="breadcrumb-item" (click)="goToSolicitud()">
                <a href="javascript:void(0)"> {{ folio }} </a>
              </li>
              <li class="breadcrumb-item">
                <a>
                  {{ actividad.factura.titulo }}
                </a>
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!--<a
            class="btn btn-sm btn-neutral"
            style="cursor: pointer"
            (click)="clickOpenModalNewActividad()"
          >
            Agregar actividad
          </a>

          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-lg-6">
              <h6 class="text-muted text-uppercase ls-1 mb-1">
                Detalles de Solicitud {{ folio }}
              </h6>
              <h2 class="h3 mb-0">Actividad {{ actividad.factura.titulo }}</h2>
            </div>
            <div class="col-lg-6 text-right">
              <button class="btn btn-link" (click)="refresh()">
                <i class="fa fa-recycle" aria-hidden="true"></i>
                Refrescar
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div>
            <h4>Servicios</h4>
            <div class="table-responsive">
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Seccion</th>
                    <th>Servicio</th>
                    <th>Estatus</th>
                    <th>Documento finalización</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let ser of actividad.factura.servicios_factura_mensual;
                      index as i
                    "
                  >
                    <td>{{ ser.seccion }}</td>
                    <td>{{ ser.servicio }}</td>
                    <td>
                      <select
                        name="selestatus"
                        id="selestatus"
                        class="form-control"
                        [(ngModel)]="ser.estatus"
                        (change)="changeEstatus(ser.id, ser.estatus)"
                        style="width: 120px;"
                      >
                        <option [value]="'Pendiente'">Pendiente</option>
                        <option [value]="'En proceso'">En proceso</option>
                        <option [value]="'Finalizado'">Finalizado</option>
                      </select>
                    </td>
                    <td>
                      <div *ngIf="ser.documento_finalizacion == null">
                        <input
                          #fileDocFinalizacion
                          type="file"
                          class="form-control"
                          name="fileDocFinalizacion"
                          id="fileDocFinalizacion"
                          (change)="uploadDocumentoFinalizacion($event, ser.id)"
                          hidden
                        />
                        <button
                          class="btn btn-secondary"
                          (click)="fileDocFinalizacion.click()"
                        >
                          Cargar documento finalización&nbsp;
                          <i class="fas fa-file-upload"> </i>
                        </button>
                      </div>

                      <div *ngIf="ser.documento_finalizacion != null">
                        <button
                          class="btn btn-success"
                          type="button"
                          (click)="downloadDocumentoFinalizacion(ser)"
                        >
                          <i class="fas fa-file-download"></i>
                          Descargar documento de finalización
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
