import { HistoricoFacturas } from "./HistoricoFacturas"

export class Actividad{
  id:number
  estatus:string = ""
  folio:string = ""
  fecha_inicio:string = ""
  fecha_fin:string = ""
  factura:HistoricoFacturas = new HistoricoFacturas()
  inOperaciones:boolean = false
}
