<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a routerLink="/simex/solicitudes"> Solicitudes </a>
              </li>

              <li class="breadcrumb-item">
                <a> Nueva solicitud </a>
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-danger text-white"
            routerLink="/simex/solicitudes"
            style="cursor: pointer"
          >
            Cancelar
          </a>

          <a class="btn btn-sm btn-neutral" style="cursor: pointer" (click)="saveAlert()">
            Crear solicitud
          </a>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>

          <h2 class="h3 mb-0">Nueva solicitud</h2>
        </div>


        <div class="row card-body">
          <div class="col-md-4 mb-3">
            <div class="form-group">
              <label for="razon-social">Razón social</label>
              <input type="text" class="form-control" [value]="cliente.razon_social" disabled>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-5 col-5 text-left m-2">
            <h3 class="h3 mb-0">Servicios seleccionados</h3>
          </div>
          <div class="col-lg-6 col-5 text-right m-2">
            <a
              class="btn btn-sm btn-success text-white"
              style="cursor: pointer"
              data-target="#modal-default"
              data-toggle="modal"

              (click)="openAddServicioModal(ModaladdServicio)"
            >
              Agregar
              <i class="fas fa-plus"> </i>
            </a>


            <ng-template #ModaladdServicio>
              <div class="modal-header">
                <h6 class="modal-title" id="modal-title-add-servicio">
                  Secciones y servicios
                </h6>

                <button
                  aria-label="Close"
                  class="close"
                  data-dismiss="modal"
                  type="button"
                  (click)="addServicioModal.hide()"
                >
                  <span aria-hidden="true"> × </span>
                </button>
              </div>

              <div class="modal-body">

                <div class="col-md-8 mb-3">
                  <div class="form-group">
                    <label for="razon-social">Seccion</label>
                    <select class="form-control" [(ngModel)]="idSeccionSelected">
                      <option *ngFor="let item of lstSeccionesSelect, index as i" [value]="item.id">{{item.nombre}}</option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="table-responsive">
                    <table class="table align-items-center table-flush" *ngFor="let seccion of lstSecciones; let i = index" [hidden]="idSeccionSelected != seccion.id">
                      <thead class="thead-light">
                        <tr>
                          <th>Servicio</th>
                          <th>Seleccionar</th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr *ngFor="let servicio of seccion.servicios; let e = index">
                          <td style="white-space: pre-line;">{{servicio.nombre}}</td>
                          <td>

                              <div class=" custom-control custom-checkbox mb-3">
                                <input
                                  class=" custom-control-input"
                                  id="customCheck-{{i}}-{{e}}"
                                  type="checkbox"
                                  [checked]="servicio.checked"
                                  (change)="changeCheckedServicio(i,e)"
                                />

                                <label class=" custom-control-label" for="customCheck-{{i}}-{{e}}">
                                </label>
                              </div>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

              <div class="modal-footer">
                <button
                  class="btn btn-link ml-auto"
                  data-dismiss="modal"
                  type="button"
                  (click)="addServicioModal.hide()"
                >
                  Cerrar
                </button>
              </div>
            </ng-template>


          </div>
        </div>

        <!--
        <ngx-datatable
          class="bootstrap selection-cell"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [rows]="lstServiciosSelected">
          <ngx-datatable-column name="Seccion" prop="seccion"></ngx-datatable-column>
          <ngx-datatable-column name="Servicio" prop="servicio"></ngx-datatable-column>
          <ngx-datatable-column name="Acciones">
            <ng-template ngx-datatable-cell-template let-row="row">
              <a
                class="table-action table-action-delete"
                tooltip="Ver detalles"
                placement="top"
                href="javascript:void(0)"
              >
                <i class="fas fa-trash"> </i>
              </a>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        -->
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <th>Seccion</th>
              <th>Servicio</th>
              <th>Remover</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstServiciosSelected;let i = index">
                <td style="white-space: pre-line;">{{item.seccion}}</td>
                <td style="white-space: pre-line;">{{item.servicio}}</td>
                <td>
                  <a
                    class="table-action table-action-delete"
                    href="javascript:void(0)"
                    (click)="changeCheckedServicio(item.index_seccion, item.index_servicio)"
                  >
                    <i class="fas fa-trash"> </i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</div>
