import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Authenticate } from 'src/app/models/Authenticate';
import { Usuario } from '../models/Usuario';
@Injectable({
  providedIn: 'root'
})
export class PerfilService {
  url:string = environment.url
  constructor(private http: HttpClient) { }

  public getUser(): Observable<any>{
    return this.http.get(`${this.url}/profile/v1/getUserProfile`);
  }

  public uploadFotoPerfil(formData:FormData): Observable<any>{
    return this.http.post(`${this.url}/profile/v1/uploadFoto`,formData)
  }

  public update(usuario:Usuario): Observable<any>{
    return this.http.put(`${this.url}/profile/v1/update`,usuario)
  }

}
