import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FacturaService {
  url:string = environment.url
  constructor(private http: HttpClient) { }

  findAll(formData:FormData):Observable<any>{
    const params = this.formDataToHttpParams(formData);
    return this.http.get(`${this.url}/facturas/v1/findAll`, {params});
  }

  addCotizacion(formData:FormData):Observable<any>{
    return this.http.put(`${this.url}/facturas/v1/addCotizacion`,formData);
  }

  aprobarSolicitud(formData:FormData):Observable<any>{
    return this.http.put(`${this.url}/facturas/v1/aprobarSolicitud`,formData);
  }

  aprobarPago(formData:FormData):Observable<any>{
    return this.http.put(`${this.url}/facturas/v1/aprobarPago`,formData);
  }

  uploadFichaPago(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/uploadFichaPago`,formData)
  }

  downloadFichaPago(id:number): Observable<any>{
    return this.http.get(`${this.url}/facturas/v1/downloadFichaPago/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  deleteFichaPago(folio:string){
    return this.http.delete(`${this.url}/facturas/v1/deleteFichaPago/${folio}`)
  }

  enviarNotificacion(formData:FormData): Observable<any>{
    return this.http.post(`${this.url}/facturas/v1/enviarNotificacion`,formData);
  }

  reactivarSolicitud(formData:FormData):Observable<any>{
    return this.http.put(`${this.url}/facturas/v1/reactivar`,formData);
  }

  uploadCotizacion(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/uploadCotizacion`,formData)
  }

  downloadCotizacion(id:number): Observable<any>{
    return this.http.get(`${this.url}/facturas/v1/downloadCotizacion/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  deleteCotizacion(folio:string){
    return this.http.delete(`${this.url}/facturas/v1/deleteCotizacion/${folio}`)
  }

  uploadCotizacionFirmada(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/uploadCotizacionFirmado`,formData)
  }

  downloadCotizacionFirmada(id:number): Observable<any>{
    return this.http.get(`${this.url}/facturas/v1/downloadCotizacionFirmado/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  deleteCotizacionFirmada(folio:string){
    return this.http.delete(`${this.url}/facturas/v1/deleteCotizacionFirmado/${folio}`)
  }

  uploadFactura(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/uploadFactura`,formData)
  }

  downloadFactura(id:number): Observable<any>{
    return this.http.get(`${this.url}/facturas/v1/downloadFactura/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  deleteFactura(folio:string){
    return this.http.delete(`${this.url}/facturas/v1/deleteFactura/${folio}`)
  }

  enviarFacturaMensual(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/enviarFacturaMensual`, formData)
  }

  findHistoricoFacturaByFolio(folio:string):Observable<any>{
    return this.http.get(`${this.url}/facturas/v1/findHistoricoFacturaByFolio/${folio}`);
  }

  downloadFacturaMensual(id:number): Observable<any>{
    return this.http.get(`${this.url}/facturas/v1/downloadFacturaMensual/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  findAllActividad(folio:string): Observable<any>{
    return this.http.get(`${this.url}/facturas/v1/findAllActividad/${folio}`);
  }


  findActividad(id:number): Observable<any>{
    return this.http.get(`${this.url}/facturas/v1/findActividad/${id}`);
  }

  saveActividad(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/saveActividad`, formData)
  }

  actividadOperaciones(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/actividadOperaciones`, formData)
  }

  subirFacturaActividad(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/subirFacturaActividad`, formData)
  }

  subirComprobanteActividad(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/subirComprobanteActividad`, formData)
  }

  agregarComentarioServicio(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/agregarComentarioServicio`, formData)
  }

  visualizacionDocumentoAprobacion(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/visualizacionDocumentoAprobacion`, formData)
  }

  changeActividadEstatus(formData:FormData){
    return this.http.put(`${this.url}/facturas/v1/changeActividadEstatus`, formData)
  }

  private formDataToHttpParams(formData: FormData): HttpParams {
    let httpParams = new HttpParams();
    formData.forEach((value, key) => {
      httpParams = httpParams.append(key, String(value));
    });
    return httpParams;
  }
}
