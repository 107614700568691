import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CatalogoService } from "src/app/services/catalogo.service";
import { SolicitudService } from "src/app/services/solicitud.service";
import swal from "sweetalert2";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Solicitud } from "src/app/models/Solicitud";
import * as $ from 'jquery';
import { DocumentoTemplateSolicitud } from "src/app/models/DocumentoTemplateSolicitud";
import { DocumentoSolicitado } from "src/app/models/DocumentoSolicitado";
import { SolicitudServicio } from "src/app/models/SolicitudServicio";
import { Actividad } from "src/app/models/Actividad";
import { FacturaService } from "src/app/services/facturas.service";

@Component({
  selector: "app-solicitud",
  templateUrl: "solicitud.component.html"
})
export class SolicitudComponent implements OnInit {
  id:number
  solicitud:Solicitud = new Solicitud();
  avanzarReq:any = {
    solicitud_id:0,
    reqRegresarEstatus:false,
    comentarios_solicitud:null
  }
  editarPermission:boolean = false

  documentoTemplateNombre:string = ""
  documentoSolicitado:DocumentoSolicitado = new DocumentoSolicitado()
  comentarioDocCliente:string = ""
  nombreDocumentoSolicitado:string = ""

  comentariosDocSolicitado:string = ""
  isNotFinalizado:boolean = true

  lstActividad: Actividad[] = []
  actividadSelected:Actividad = new Actividad()

  opEsp:boolean = false

  actividadModal: BsModalRef;
  settingsActividadModal = {
    keyboard: true,
    class: "modal-dialog-centered modal-xl"
  };

  titulo:string = ""
  mensaje:string = ""

  serviciosts: any[] = []
  serviciostsAll:boolean = false
  constructor(
    private solicitudService:SolicitudService,
    private catalogoService:CatalogoService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private facturaService: FacturaService) {}

  ngOnInit() {
    let permisos:any[] = JSON.parse(localStorage.getItem("modulos"))
    let mp:any = permisos.find(x => x["modulo"]==="SOLICITUDES")
    if(mp["permisos"]["editar"]){
      this.editarPermission = true
    }

    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.findById()
    this.checkRolOperacionesEsp()
  }

  findById(){
    this.solicitudService.findById(this.id).subscribe(success=>{
      this.solicitud = success
      if(this.solicitud.estatus_solicitud.clave == 'FINALIZADO'){
        this.isNotFinalizado = false
      }
      this.findActividadByFolioAndInOperaciones()
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  sweetAlert(icon,title:string,button:string,text:string){
    swal.fire({
      title: title,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  uploadDocumentoTemplate($event){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("solicitud_id",String(this.solicitud.id))
    formData.append("nombre_documento", this.documentoTemplateNombre)
    this.solicitudService.uploadDocumentoTemplate(formData).subscribe(success=>{
      $("#inputPlantilla").val("")
      this.documentoTemplateNombre = ""
      this.findById()
      this.swalNotification("Excelente!!","Se cargo el documento correctamente","success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  deleteDocumentoTemplateAlert(item){
    var that = this
    swal.fire({
      title: `¿Estas seguro que deseas eliminar el documento "${item.nombre_documento}"?`,
      text:`Al eliminar la plantilla se elimina el documento cargado por el cliente "En caso de que el cliente ya lo haya cargado"`,
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
      icon:"question"
    }).then((result) => {
      if (result.isConfirmed) {
        that.deleteDocumentoTemplate(item.id)
      }
    })
  }

  deleteDocumentoTemplate(id:number){
    this.solicitudService.deleteDocumentoTemplate(id).subscribe(success=>{
      this.swalNotification("Excelente!!","Se eliminó el documento correctamente","success")
      this.findById()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadDocumentoTemplate(item:DocumentoTemplateSolicitud){
    this.solicitudService.downloadDocumentoTemplate(item.id).subscribe(success=>{
        // Crear un Blob a partir del ByteArray
        const blob = new Blob([success], { type: item.content_type_template });
        // Crear una URL Blob a partir del Blob
        const blobUrl = URL.createObjectURL(blob);
       // Crea un enlace y simula el clic para descargar el archivo
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = item.file_name_template;
        a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }


  uploadDocumentoSolicitado($event, id:number){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("id",String(id))
    this.solicitudService.uploadDocumentoSolicitado(formData).subscribe(success=>{
      $("#inputDocCliente").val("")
      this.findById()
      this.swalNotification("Excelente!!","Se cargo el documento correctamente","success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadDocumentoSolicitado(item:DocumentoSolicitado){
    this.solicitudService.downloadDocumentoSolicitado(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  deleteDocumentoSolicitadoAlert(item){
    var that = this
    swal.fire({
      title: `¿Estas seguro que deseas eliminar el documento del cliente?`,
      text:`${item.file_name}`,
      input: 'textarea',
      inputPlaceholder: 'Comentarios...',
      inputAttributes: {
        'aria-label': 'Escriba su comentario'
      },
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
      icon:"question",
      preConfirm: (texto) => {
        that.comentariosDocSolicitado = texto
      }}).then((result) => {
        if (result.isConfirmed) {
          that.deleteDocumentoSolicitado(item.id)
        }
      })
  }

  deleteDocumentoSolicitado(id:number){
    const formData = new FormData();
    formData.append("id", String(id))
    formData.append("comentario",this.comentariosDocSolicitado)
    this.solicitudService.deleteDocumentoSolicitado(formData).subscribe(success=>{
      this.swalNotification("Excelente!!","Se eliminó el documento correctamente","success")
      this.findById()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  uploadDocumentoTemplateCliente($event, id:number){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("id",String(id))
    this.solicitudService.uploadDocumentoTemplateCliente(formData).subscribe(success=>{
      $("#inputPlantillaCliente").val("")
      this.findById()
      this.swalNotification("Excelente!!","Se cargo el documento correctamente","success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadDocumentoTemplateCliente(item:DocumentoTemplateSolicitud){
    this.solicitudService.downloadDocumentoTemplateCliente(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type_cliente });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name_cliente;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  deleteDocumentoTemplateClienteAlert(item){
    var that = this
    swal.fire({
      title: `¿Estas seguro que deseas eliminar el documento del cliente?`,
      text:`${item.file_name_cliente}`,
      input: 'textarea',
      inputPlaceholder: 'Comentarios...',
      inputAttributes: {
        'aria-label': 'Escriba su comentario'
      },
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
      icon:"question",
      preConfirm: (texto) => {
        that.comentarioDocCliente = texto
      }}).then((result) => {
        if (result.isConfirmed) {
          that.deleteDocumentoTemplateCliente(item.id)
        }
      })
  }

  deleteDocumentoTemplateCliente(id:number){
    const formData = new FormData();
    formData.append("id", String(id))
    formData.append("comentario",this.comentarioDocCliente)
    this.solicitudService.deleteDocumentoTemplateCliente(formData).subscribe(success=>{
      this.swalNotification("Excelente!!","Se eliminó el documento correctamente","success")
      this.findById()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }


  saveDocumentoSolicitado(){
    this.solicitudService.saveDocumentoSolicitado(this.solicitud.id, this.nombreDocumentoSolicitado).subscribe(success=>{
      this.nombreDocumentoSolicitado = ""
      this.findById()
      this.swalNotification("Excelente!!","Se agrego el documento solicitado correctamente","success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  getEstatusClass(estatus:string):string{
    if(estatus=="PENDIENTE"){
      return "bg-warning"
    }else if(estatus=="SOLICITUD DE INFORMACION"){
      return "bg-info"
    }else if (estatus=="EN REVISION") {
      return "bg-warning"
    }else if (estatus=="EN PROCESO") {
      return "bg-success"
    }else if (estatus=="FINALIZADO") {
      return "bg-danger"
    }
  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  regresarASolicitudInformacion(){
    var that = this
    swal.fire({
      title: 'Ingrese su comentario',
      input: 'textarea',
      inputPlaceholder: 'Escriba aquí...',
      inputAttributes: {
        'aria-label': 'Escriba su comentario'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      preConfirm: (texto) => {
        that.avanzarReq.comentarios_solicitud = texto
      }}).then((result) => {
        if (result.isConfirmed) {
          that.avanzarReq.reqRegresarEstatus = true
          that.avanzarSolicitud()
        }
    })
  }

  avanzarSolicitud(){
    this.avanzarReq.solicitud_id = this.solicitud.id
    this.solicitudService.avanzarSolicitud(this.avanzarReq).subscribe(success=>{
      this.findById()
      this.avanzarReq = {
        solicitud_id:0,
        reqRegresarEstatus:false,
        comentarios_solicitud:null
      }
      this.swalNotification("Excelente!!","Se avanzó la solicitud correctamente","success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  avanzarAlert(){
    var that = this
    swal.fire({
      title: '¿Estas seguro que deseas avanzar la solicitud?',
      text:`La solicitud cambiara su estatus a "${that.getNextEstatus()}"`,
      showDenyButton: true,
      confirmButtonText: 'Avanzar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.avanzarSolicitud()
      }
    })
  }

  solicitarInfoAlert(){
    var that = this
    swal.fire({
      title: '¿Deseas solicitar información?',
      showDenyButton: true,
      confirmButtonText: 'Solicitar información',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.avanzarSolicitud()
      }
    })
  }

  solicitarInformacion(){
    this.avanzarReq.solicitud_id = this.solicitud.id
    this.solicitudService.solicitarInformacion(this.avanzarReq).subscribe(success=>{
      this.findById()
      this.avanzarReq = {
        solicitud_id:0,
        reqRegresarEstatus:false,
        comentarios_solicitud:null
      }
      this.swalNotification("Excelente!!","Se hizo la solicitud de información correctamente","success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  getNextEstatus():String{
    if(this.solicitud.estatus_solicitud.clave=='PENDIENTE'){
      return "Solicitud de información"
    }else if(this.solicitud.estatus_solicitud.clave=='SOLICITUD DE INFORMACION'){
      return "En revisión"
    }else if(this.solicitud.estatus_solicitud.clave=='EN REVISION'){
      return "En proceso"
    }else if(this.solicitud.estatus_solicitud.clave=='EN PROCESO'){
      return "Finalizado"
    }
  }

  avanzarServicioAlert(item:SolicitudServicio){
    var that = this
    swal.fire({
      title: `¿Estas seguro que deseas ${item.estatus=='Pendiente'?'avanzar':'finalizar'} el servicio?`,
      text:`${item.servicio.nombre }`,
      showDenyButton: true,
      confirmButtonText: `${item.estatus=='Pendiente'?'Avanzar':'Finalizar'}`,
      denyButtonText: `Cancelar`,
      icon:"question"
    }).then((result) => {
        if (result.isConfirmed) {
          that.avanzarServicio(item)
        }
      })
  }

  avanzarServicio(item:SolicitudServicio){
    this.solicitudService.avanzarServicio(item.id).subscribe(success=>{
      this.findById()
      this.swalNotification("Excelente!!",`Se ${item.estatus=='Pendiente'?'avanzó':'finalizó'} el servicio correctamente`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  findActividadByFolioAndInOperaciones(){
    this.solicitudService.findActividadByFolioAndInOperaciones(this.solicitud.folio).subscribe(success=>{
      console.log(success)
      this.lstActividad = success
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  goToActividad(id:number){
    this.router.navigate([`/simex/solicitudes/actividad/${this.solicitud.folio}/${this.solicitud.id}/${id}`]);
  }

  checkRolOperacionesEsp(){
    if(String(localStorage.getItem("roles")).includes("OPERACIONES_ESP")){
      this.opEsp = true
    }
  }

  openActividadModal(modalDefault: TemplateRef<any>) {
    this.actividadModal = this.modalService.show(modalDefault, this.settingsActividadModal);
  }

  clickOpenModalNewActividad(){
    this.serviciosts = []
    this.titulo = ""
    this.mensaje = ""
    this.serviciosts = this.solicitud.servicios
    for(let i = 0; i < this.serviciosts.length; i++){
      this.serviciosts["checked"] = false
    }
    $("#btn-modal-new-actividad").click();
  }

  saveActividadAlert(){
    this.actividadModal.hide()
    this.saveActividad()
  }

  saveActividad(){
    this.solicitudService.saveActividad(this.getFormDataFacturaMensual()).subscribe(success=>{
      this.swalNotification("Excelente!!",`Se creo la actividad correctamente.`,"success")
      this.findById()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  getFormDataFacturaMensual(): FormData{
    const formData = new FormData
    let servicios:any = []
    for(let ser of this.serviciosts){
      if(ser["checked"]){
        servicios.push({
          "seccion":ser.seccion.nombre,
          "servicio":ser.servicio.nombre,
          "estatus":"Pendiente"
        })
      }
    }
    formData.append("servicios", JSON.stringify(servicios))
    formData.append("folio", this.solicitud.folio)
    formData.append("titulo", this.titulo)
    formData.append("mensaje", this.mensaje)
    return formData
  }

  changeCheckedServicio(i:number){
    let checked = !this.serviciosts[i]["checked"]
    this.serviciosts[i]["checked"] = checked
    if(checked){
      this.serviciostsAll = true
    }
  }


  changeCheckedAll(){
    let checked = !this.serviciostsAll
    this.serviciostsAll = checked
    for(let i = 0; i < this.serviciosts.length; i++){
      this.serviciosts[i]["checked"] = checked
    }
  }
}
