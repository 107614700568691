import { Component} from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject } from "rxjs";
@Component({
  selector: 'app-spinner',
  template: `
  <div class="overlay" *ngIf="isLoading$ | async">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
  `,
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent{
  isLoading$ = new Subject<Boolean>();
  constructor(private spinnerService:SpinnerService){
    this.isLoading$ = this.spinnerService.isLoading$;
  }

}
