import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Solicitud } from "src/app/models/Solicitud";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import swal from "sweetalert2";
import * as $ from 'jquery';
import { FacturaService } from "../../../services/facturas.service";
import { ComprobantePagoSolicitud } from "src/app/models/ComprobantePagoSolicitud";
import { DocumentoCotizacion } from "src/app/models/DocumentosCotizacion";
import { HistoricoFacturas } from "src/app/models/HistoricoFacturas";
import { DocumentoFacturaMensual } from "src/app/models/DocumentoFacturaMensual";
import { SolicitudService } from "src/app/services/solicitud.service";
import { Actividad } from "src/app/models/Actividad";
import { ServiciosFacturaMensual } from "src/app/models/ServiciosFacturaMensual";
@Component({
  selector: "app-facturas-actividad",
  templateUrl: "facturas-actividad.component.html",
  styleUrls:['facturas-actividad.component.css']
})
export class FacturasActividadComponent implements OnInit {

  solicitud:Solicitud = new Solicitud()




  tipoNotificacion:string = "seleccionar"
  titulo:string = ""
  mensaje:string = ""

  serviciosts: any[] = []
  serviciostsAll:boolean = false
  docsFacturasServicios: any[] =[]

  actividad:Actividad = new Actividad()
  folio:string = ""
  id:number = 0
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private facturaService: FacturaService,
    private solicitudService: SolicitudService,
    private route: ActivatedRoute) {}

  ngOnInit() {

    var that = this
    $(document).ready(function() {
      // Seleccionamos el input por su ID
      var $miInput = $("#input-buscar-solicitud");

      // Agregamos el evento keypress al input
      $miInput.on("keypress", function(event) {
        // Comprobamos si la tecla presionada es "Enter" (código 13)
        if (event.which === 13) {
          // Aquí puedes realizar la acción que desees cuando se presione "Enter"
          that.searchData()
          // Evitamos el comportamiento por defecto del Enter en un formulario
          event.preventDefault();
        }
      });
    });

    this.folio = this.route.snapshot.paramMap.get('folio');
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.findActividad()
  }

  findActividad(){
    this.facturaService.findActividad(this.id).subscribe(success=>{
      this.actividad = success
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  refresh(){
  }

  sweetAlert(icon,title:string,button:string,text:string){
    swal.fire({
      title: title,
      text: "",
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  getEstatusClass(estatus:string):string{
    if(estatus=="Pendiente"){
      return "bg-warning"
    }else if (estatus=="En proceso") {
      return "bg-success"
    }else if (estatus=="Finalizado"||estatus=="Cancelado") {
      return "bg-danger"
    }
  }


  searchData(){
    let search = String($("#input-buscar-solicitud").val())
    /*
    this.temp = this.solicitudes.filter((item) => {
      // Modifica esta lógica según tus necesidades
      return (
        item.folio.toUpperCase().includes(search.toUpperCase()) ||
        item.cliente.razon_social.toUpperCase().includes(search.toUpperCase()) ||
        item.estatus_solicitud.nombre.toUpperCase().includes(search.toUpperCase()) ||
        item.servicios.length.toString().includes(search)
      );
    });
    */
  }

  downloadFichaPago(item:ComprobantePagoSolicitud){
    this.facturaService.downloadFichaPago(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }


  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }


  uploadFactura($event:any, folio:string){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("folio", folio)
    this.facturaService.uploadFactura(formData).subscribe(success=>{
      this.findActividad()
      this.swalNotification("Excelente!!",`Se cargo la factura de solicitud con folio ${folio}.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadFactura(item:DocumentoCotizacion){
    this.facturaService.downloadFactura(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }



  subirFacturaActividad($event){
    let id_factura = String(this.actividad.factura.id)
    const formData = new FormData()
    formData.append("documento",$event.target.files[0])
    formData.append("id_factura", id_factura)
    this.facturaService.subirFacturaActividad(formData).subscribe(success=>{
      this.findActividad()
      this.swalNotification("Excelente!!",`Se cargó la factura correctamente.`,"success")
      $("#file-factura-servicion").val("")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  subirComprobanteActividad($event){
    let id_factura = String(this.actividad.factura.id)
    const formData = new FormData()
    formData.append("documento",$event.target.files[0])
    formData.append("id_factura", id_factura)
    this.facturaService.subirComprobanteActividad(formData).subscribe(success=>{
      this.findActividad()
      this.swalNotification("Excelente!!",`Se cargó el comprobante correctamente.`,"success")
      $("#file-comprobante-servicion").val("")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadComprobantePago(item:ComprobantePagoSolicitud){
    this.facturaService.downloadFichaPago(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  agregarObservacionAlert(servicio_id:number){
    var that = this
    // Muestra la alerta de SweetAlert con el textarea
    swal.fire({
      title: 'Ingresa una observación:',
      html: '<textarea class="form-control" id="obs" name="obs" rows="5"></textarea>', // Convierte el elemento textarea a HTML
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
          // Obtiene el valor del textarea cuando se hace clic en Aceptar
          return $('#obs').val();
      }
    }).then((result) => {
        // Maneja el resultado después de cerrar la alerta
        if (result.isConfirmed) {
          that.agregarObservacion(servicio_id, result.value);
        }
    });
  }


  agregarObservacion(servicio_id:number, observacion:any){
    const formData = new FormData()
    formData.append("servicio_id",String(servicio_id))
    formData.append("observacion",observacion)
    this.facturaService.agregarComentarioServicio(formData).subscribe(success=>{
      this.findActividad();
      this.swalNotification("Excelente!!",`Se agrego la observación correctamente.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }


  visualizacionDocumentoAprobacion(servicio_id:number, vistaDocumentoAprobado:boolean){
    const formData = new FormData()
    formData.append("servicio_id",String(servicio_id))
    formData.append("aprobado",
      vistaDocumentoAprobado?"false":"true"
    )
    this.facturaService.visualizacionDocumentoAprobacion(formData).subscribe(success=>{
      this.findActividad();
      this.swalNotification("Excelente!!",`EL hizo la actualización correctamente.`,"success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  downloadDocumentoFinalizacion(item:ServiciosFacturaMensual){
    this.solicitudService.downloadDocumentoFinalizacion(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.documento_finalizacion.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.documento_finalizacion.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  goToDetalles(){
    this.router.navigate([`/simex/facturas-detalles/${this.folio}`]);
  }


  downloadFacturaMensual(item:DocumentoFacturaMensual){
    this.facturaService.downloadFacturaMensual(item.id).subscribe(success=>{
      // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });
      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);
     // Crea un enlace y simula el clic para descargar el archivo
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = item.file_name;
      a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

}
