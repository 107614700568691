<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a  routerLink="/simex/facturas"> Facturas </a>
              </li>
              <li class="breadcrumb-item">
                <a> {{folio}} </a>
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-neutral"
            style="cursor: pointer"
            (click)="clickOpenModalNewActividad()"
          >
            Agregar actividad
          </a>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-lg-6">
              <h6 class="text-muted text-uppercase ls-1 mb-1">
                Administración
              </h6>
              <h2 class="h3 mb-0">Detalles de Solicitud {{ folio }}</h2>
            </div>
            <div class="col-lg-6 text-right">
              <button class="btn btn-link" (click)="refresh()">
                <i class="fa fa-recycle" aria-hidden="true"></i>
                Refrescar
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <label>Cliente</label>
              <input
                type="text"
                class="form-control"
                [value]="solicitud.cliente.razon_social"
                disabled
              />
            </div>
            <div class="col-md-4">
              <label>Responsable de la actividad</label>
              <input
                type="text"
                class="form-control"
                [value]="solicitud.cliente.responsable_actividad"
                disabled
              />
            </div>
            <div class="col-md-4">
              <label>Correo electrónico</label>
              <input
                type="text"
                class="form-control"
                [value]="solicitud.cliente.email"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <th>Sección</th>
              <th>Servicio</th>
              <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let item of solicitud.servicios; let i = index">
                <td style="white-space: pre-line">{{ item.seccion.nombre }}</td>
                <td style="white-space: pre-line">
                  {{ item.servicio.nombre }}
                </td>
              </tr>
            </tbody>
          </table>

          <div class="card-footer py-4"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="col-lg-5 col-5 text-left m-2">
            <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>
            <h2 class="h3 mb-0">Actividades</h2>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <th>Titulo</th>
              <th>estatus</th>
              <th>Fecha inicio</th>
              <th>Fecha fin</th>

              <th style="text-align: right">Acciones</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstActividad; let i = index">
                <td style="white-space: pre-line">{{ item.factura.titulo }}</td>
                <td style="white-space: pre-line">

                  <span class="badge badge-dot mr-4">
                    <i
                      [class]="
                        getEstatusActividadClass(item.estatus) + ' bg-dot'
                      "
                    >
                    </i>
                    <span class="status"> {{ item.estatus }} </span>
                  </span>

                </td>
                <td style="white-space: pre-line">
                  {{ item.fecha_inicio }}
                </td>
                <td style="white-space: pre-line">
                  {{ item.fecha_fin == null ? "N/A" : item.fecha_fin }}
                </td>
                <td>
                  <div class="text-right">
                    <div class="dropdown no-caret" dropdown [dropup]="'true'">
                      <a
                        aria-controls="dropdown-basic"
                        class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                        dropdownToggle
                        href="javascript:void(0)"
                        role="button"
                      >
                        <i class="fas fa-ellipsis-v"> </i>
                      </a>

                      <div
                        class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                        *dropdownMenu
                      >
                        <a
                          href="javascript:void(0)"
                          class="dropdown-item"

                          (click)="goToActividad(item.id)"
                        >
                        <!--(click)="clickOpenModalVerActividad(item)"-->
                          Ver
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-item"
                          (click)="actividadOperaciones(item.id)"
                          *ngIf="!item.inOperaciones"
                        >
                          Enviar a operaciones
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="dropdown-item"
                          *ngIf="item.inOperaciones"
                          (click)="changeEstatusActividadAlert('Finalizar',item.id)"
                        >
                        <!--(click)="clickOpenModalVerActividad(item)"-->
                          Finalizar
                        </a>
                        <!--
                        <a
                          href="javascript:void(0)"
                          class="dropdown-item"
                          *ngIf="item.inOperaciones"
                          (click)="changeEstatusActividadAlert('Cancelar',item.id)"
                        >
                          Cancelar
                        </a>
                        -->
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="card-footer py-4"></div>
        </div>
      </div>
    </div>
  </div>

  <!--             MODALES              -->
  <div>
    <!-- -->
    <button
      id="btn-modal-new-actividad"
      data-target="#modal-default"
      data-toggle="modal"
      type="button"
      (click)="openActividadModal(modalActividad)"
      hidden
    >
      Default
    </button>

    <ng-template #modalActividad>
      <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">Nueva actividad</h6>

        <button
          aria-label="Close"
          class="close"
          data-dismiss="modal"
          type="button"
          (click)="actividadModal.hide()"
        >
          <span aria-hidden="true"> × </span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Titulo</label>
              <input
                type="text"
                class="form-control"
                name="titulo_actividad"
                id="titulo_actividad"
                [(ngModel)]="titulo"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Mensaje</label>
              <textarea
                name="mensaje_actividad"
                id="mensaje_actividad"
                cols="30"
                rows="7"
                class="form-control"
                [(ngModel)]="mensaje"
              ></textarea>
            </div>
          </div>
        </div>
        <h4>Servicios</h4>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>
                  <div class="custom-control custom-checkbox mb-3">
                    <input
                      class="custom-control-input"
                      id="customCheck-all"
                      type="checkbox"
                      [checked]="serviciostsAll"
                      (change)="changeCheckedAll()"
                    />

                    <label class="custom-control-label" for="customCheck-all">
                    </label>
                  </div>
                </th>
                <th>Sección</th>
                <th>Servicio</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of serviciosts; index as i">
                <td>
                  <div class="custom-control custom-checkbox mb-3">
                    <input
                      class="custom-control-input"
                      id="customCheck-{{ i }}"
                      type="checkbox"
                      [checked]="item.checked"
                      (change)="changeCheckedServicio(i)"
                    />

                    <label
                      class="custom-control-label"
                      for="customCheck-{{ i }}"
                    >
                    </label>
                  </div>
                </td>
                <td>{{ item.seccion.nombre }}</td>
                <td>{{ item.servicio.nombre }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>Facturas</h4>
        <div class="form-group">
          <input
            #filefacturaservicio
            type="file"
            class="form-control"
            name="file-factura-servicio"
            id="file-factura-servicio"
            (change)="cargarDocumentFacturaServicio($event)"
            hidden
          />
          <button
            class="btn btn-secondary"
            (click)="filefacturaservicio.click()"
          >
            Cargar documento &nbsp;
            <i class="fas fa-file-upload"> </i>
          </button>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Nombre documento</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of docsFacturasServicios; index as i">
                <td>{{ item.filename }}</td>
                <td>
                  <button
                    class="btn btn-danger"
                    type="button"
                    (click)="eliminarDocumentoFacturaServicio(i)"
                  >
                    <i class="fas fa-trash"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal-footer">
        <button
          class="btn btn-link ml-auto"
          data-dismiss="modal"
          type="button"
          (click)="actividadModal.hide()"
        >
          Cerrar
        </button>
        <button
          class="btn btn-success"
          type="button"
          (click)="saveActividadAlert()"
        >
          Crear
        </button>
      </div>
    </ng-template>

    <button
      id="btn-modal-notificaciones"
      data-target="#modal-notificaciones"
      data-toggle="modal"
      type="button"
      (click)="openNotificacionesModal(modalNotificaciones)"
      hidden
    >
      modal notificaciones
    </button>

    <ng-template #modalNotificaciones>
      <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">Notificaciones</h6>

        <button
          aria-label="Close"
          class="close"
          data-dismiss="modal"
          type="button"
          (click)="notificacionesModal.hide()"
        >
          <span aria-hidden="true"> × </span>
        </button>
      </div>

      <div class="modal-body">
        <h3>
          Esta notificación se enviará al cliente "{{
            solicitudSelected.cliente.razon_social
          }}"
        </h3>
        <br />
        <div class="form-group">
          <label>Tipo de notificación</label>
          <select
            name="tiponotificacion"
            class="form-control"
            [(ngModel)]="tipoNotificacion"
          >
            <option
              *ngIf="tipoNotificacion == 'seleccionar'"
              [value]="'seleccionar'"
            >
              Seleccionar...
            </option>
            <option
              *ngIf="solicitudSelected.cotizacion_aceptada"
              [value]="'pendiente_pago'"
            >
              Pendiente de pago
            </option>
            <option [value]="'facturas'">Enviar facturas</option>
            <option [value]="'personalizado'">Personalizado</option>
          </select>
        </div>
        <div *ngIf="tipoNotificacion == 'personalizado'">
          <hr />
          <div class="form-group">
            <label>Titulo de notificación</label>
            <input
              name="tituloNotificacion"
              type="text"
              class="form-control"
              [(ngModel)]="tituloNotificacion"
            />
          </div>
          <div class="form-group">
            <label>Mensaje</label>
            <textarea
              name="mensajeNotificacion"
              id=""
              cols="30"
              rows="10"
              class="form-control"
              [(ngModel)]="mensajeNotificacion"
            ></textarea>
          </div>
        </div>
        <div *ngIf="tipoNotificacion == 'facturas'">
          <hr />
          <div class="form-group">
            <label>Titulo de notificación</label>
            <input
              name="tituloNotificacion"
              type="text"
              class="form-control"
              [(ngModel)]="tituloNotificacion"
            />
          </div>
          <div class="form-group">
            <label>Mensaje</label>
            <textarea
              name="mensajeNotificacion"
              id=""
              cols="30"
              rows="10"
              class="form-control"
              [(ngModel)]="mensajeNotificacion"
            ></textarea>
          </div>
          <h4>Servicios</h4>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox mb-3">
                      <input
                        class="custom-control-input"
                        id="customCheck-all"
                        type="checkbox"
                        [checked]="serviciostsAll"
                        (change)="changeCheckedAll()"
                      />

                      <label class="custom-control-label" for="customCheck-all">
                      </label>
                    </div>
                  </th>
                  <th>Sección</th>
                  <th>Servicio</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of serviciosts; index as i">
                  <td>
                    <div class="custom-control custom-checkbox mb-3">
                      <input
                        class="custom-control-input"
                        id="customCheck-{{ i }}"
                        type="checkbox"
                        [checked]="item.checked"
                        (change)="changeCheckedServicio(i)"
                      />

                      <label
                        class="custom-control-label"
                        for="customCheck-{{ i }}"
                      >
                      </label>
                    </div>
                  </td>
                  <td>{{ item.seccion.nombre }}</td>
                  <td>{{ item.servicio.nombre }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4>Documentos</h4>
          <div class="form-group">
            <input
              #filefacturaservicio
              type="file"
              class="form-control"
              name="file-factura-servicio"
              id="file-factura-servicio"
              (change)="cargarDocumentFacturaServicio($event)"
              hidden
            />
            <button
              class="btn btn-secondary"
              (click)="filefacturaservicio.click()"
            >
              Cargar documento &nbsp;
              <i class="fas fa-file-upload"> </i>
            </button>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Nombre documento</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of docsFacturasServicios; index as i">
                  <td>{{ item.filename }}</td>
                  <td>
                    <button
                      class="btn btn-danger"
                      type="button"
                      (click)="eliminarDocumentoFacturaServicio(i)"
                    >
                      <i class="fas fa-trash"></i>
                      Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          class="btn btn-success"
          data-dismiss="modal"
          type="button"
          (click)="enviarNotificacion()"
          [disabled]="btnEnviarNotificacionDisabled()"
        >
          Enviar notificación
        </button>
        <button
          class="btn btn-link"
          data-dismiss="modal"
          type="button"
          (click)="notificacionesModal.hide()"
        >
          Cerrar
        </button>
      </div>
    </ng-template>

    <button
      id="btn-modal-ver-actividad"
      data-target="#modal-ver-actividad"
      data-toggle="modal"
      type="button"
      (click)="openverActividadModal(modalVerActividad)"
      hidden
    >
      modal facturas
    </button>

    <ng-template #modalVerActividad>
      <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">Ver actividad</h6>

        <button
          aria-label="Close"
          class="close"
          data-dismiss="modal"
          type="button"
          (click)="verActividadsModal.hide()"
        >
          <span aria-hidden="true"> × </span>
        </button>
      </div>

      <div class="modal-body">
        <h4>Servicios</h4>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Seccion</th>
                <th>Servicio</th>
                <th>Estatus</th>
                <th>Documento finalizado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let ser of actividadSelected.factura.servicios_factura_mensual
                "
              >
                <td>{{ ser.seccion }}</td>
                <td>{{ ser.servicio }}</td>
                <td>{{ ser.estatus }}</td>
                <td></td>
                <td>
                  <div class="text-right">
                    <div class="dropdown no-caret" dropdown [dropup]="'true'">
                      <a
                        aria-controls="dropdown-basic"
                        class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                        dropdownToggle
                        href="javascript:void(0)"
                        role="button"
                      >
                        <i class="fas fa-ellipsis-v"> </i>
                      </a>

                      <div
                        class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                        *dropdownMenu
                      >
                        <a href="javascript:void(0)" class="dropdown-item">
                          Aprobar visualización de documento
                        </a>
                        <a href="javascript:void(0)" class="dropdown-item">
                          Agregar observación
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>Facturas</h4>
        <input
          #filefacturaservicion
          type="file"
          class="form-control"
          name="file-factura-servicion"
          id="file-factura-servicion"
          (change)="closeverActividadsModal(); subirFacturaActividad($event)"
          hidden
        />
        <button
          class="btn btn-secondary"
          (click)="filefacturaservicion.click()"
        >
          Cargar documento &nbsp;
          <i class="fas fa-file-upload"> </i>
        </button>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Nombre</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let fac of actividadSelected.factura
                    .documentos_factura_mensual
                "
              >
                <td>{{ fac.file_name }}</td>
                <td>
                  <button
                    class="btn btn-success"
                    type="button"
                    (click)="downloadFacturaMensual(fac)"
                  >
                    <i class="fas fa-file-download"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>Comprobantes de pago</h4>
        <input
          #filecomprobanteservicion
          type="file"
          class="form-control"
          name="file-comprobante-servicion"
          id="file-comprobante-servicion"
          (change)="closeverActividadsModal(); subirComprobanteActividad($event)"
          hidden
        />
        <button
          class="btn btn-secondary"
          (click)="filecomprobanteservicion.click()"
        >
          Cargar comprobante de pago &nbsp;
          <i class="fas fa-file-upload"> </i>
        </button>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Nombre</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let com of actividadSelected.factura.comprobantes_pago_mensual
                "
              >
                <td>{{ com.file_name }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal-footer">
        <button
          class="btn btn-link"
          data-dismiss="modal"
          type="button"
          (click)="verActividadsModal.hide()"
        >
          Cerrar
        </button>
      </div>
    </ng-template>
  </div>
  <style>
    .bg-dot {
      height: 1rem;
      width: 1rem;
    }

    .bg-none {
      background-color: black;
    }
  </style>
</div>
