import { Cliente } from "./Cliente"
import { ComprobantePagoSolicitud } from "./ComprobantePagoSolicitud"
import { DocumentoClienteSolicitud } from "./DocumentoClienteSolicitud"
import { DocumentoSolicitado } from "./DocumentoSolicitado"
import { DocumentoTemplateSolicitud } from "./DocumentoTemplateSolicitud"
import { DocumentoCotizacion } from "./DocumentosCotizacion"
import { EstatusSolicitud } from "./EstatusSolicitud"
import { SolicitudServicio } from "./SolicitudServicio"

export class Solicitud{
  id:number
  folio:string
  comentarios_solicitud:string
  servicios:SolicitudServicio[] = []
  cliente:Cliente = new Cliente()
  estatus_solicitud:EstatusSolicitud = new EstatusSolicitud()
  documentosTemplateSolicitud:DocumentoTemplateSolicitud[] = []
  documentosSolicitados:DocumentoSolicitado[] = []
  fecha_creacion:string
  fecha_actualizacion:string
  aprobado:boolean
  cotizacion_aceptada:boolean
  cotizacion:DocumentoCotizacion = new DocumentoCotizacion()
  cotizacion_firmada:DocumentoCotizacion = new DocumentoCotizacion()
  factura:DocumentoCotizacion = new DocumentoCotizacion()
  pagado:boolean
  cotizacion_rechazada:boolean
  comprobante_pago:ComprobantePagoSolicitud = new ComprobantePagoSolicitud()
}
