import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Authenticate } from 'src/app/models/Authenticate';
@Injectable({
  providedIn: 'root'
})
export class SolicitudService {
  url:string = environment.url
  constructor(private http: HttpClient) { }

  findAll(formData:FormData):Observable<any>{
    const params = this.formDataToHttpParams(formData);
    return this.http.get(`${this.url}/solicitud/v1/findAll`, {params});
  }

  findById(id:number):Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/findById/${id}`);
  }

  findByFolio(folio:string):Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/findByFolio/${folio}`);
  }

  solicitar(data:any):Observable<any>{
    return this.http.post(`${this.url}/solicitud/v1/solicitar`, data);
  }

  uploadDocumentoTemplate(formData:FormData){
    return this.http.put(`${this.url}/solicitud/v1/uploadDocumentoTemplate`,formData)
  }

  deleteDocumentoTemplate(id:number){
    return this.http.delete(`${this.url}/solicitud/v1/deleteDocumentoTemplate/${id}`)
  }

  downloadDocumentoTemplate(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadDocumentoTemplate/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  uploadDocumentoTemplateCliente(formData:FormData){
    return this.http.put(`${this.url}/solicitud/v1/uploadDocumentoTemplateCliente`,formData)
  }

  downloadDocumentoTemplateCliente(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadDocumentoTemplateCliente/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  deleteDocumentoTemplateCliente(data:FormData){
    return this.http.put(`${this.url}/solicitud/v1/deleteDocumentoTemplateCliente`,data)
  }

  saveDocumentoSolicitado(solicitud_id:number,nombre_documento:string){
    let data:FormData = new FormData()
    data.append("solicitud_id",String(solicitud_id))
    data.append("nombre_documento",nombre_documento)
    return this.http.post(`${this.url}/solicitud/v1/saveDocumentoSolictado`,data)
  }

  uploadDocumentoSolicitado(formData:FormData){
    return this.http.put(`${this.url}/solicitud/v1/uploadDocumentoSolicitado`,formData)
  }

  downloadDocumentoSolicitado(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadDocumentoSolicitado/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  deleteDocumentoSolicitado(data:FormData){
    return this.http.put(`${this.url}/solicitud/v1/deleteDocumentoSolicitado`,data)
  }


  avanzarSolicitud(data:any){
    return this.http.put(`${this.url}/solicitud/v1/avanzar`,data)
  }

  avanzarServicio(id:number){
    let data:FormData = new FormData()
    data.append("id",String(id))
    return this.http.put(`${this.url}/solicitud/v1/avanzarServicio`,data)
  }

  findActividadByFolioAndInOperaciones(folio:string):Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/findActividadByFolioAndInOperaciones/${folio}`);
  }

  cambiarEstatusActividadServicio(formData:FormData){
    return this.http.put(`${this.url}/solicitud/v1/cambiarEstatusActividadServicio`, formData)
  }

  uploadDocumentoFinalizacion(formData:FormData){
    return this.http.put(`${this.url}/solicitud/v1/uploadDocumentoFinalizacion`,formData)
  }

  downloadDocumentoFinalizacion(id:number): Observable<any>{
    return this.http.get(`${this.url}/solicitud/v1/downloadDocumentoFinalizacion/${id}`, {
      responseType: 'arraybuffer',
    })
  }

  solicitarInformacion(data:any){
    return this.http.put(`${this.url}/solicitud/v1/solicitarInformacion`,data)
  }

  saveActividad(formData:FormData){
    return this.http.post(`${this.url}/solicitud-esp/v1/saveActividad`, formData)
  }


  private formDataToHttpParams(formData: FormData): HttpParams {
    let httpParams = new HttpParams();
    formData.forEach((value, key) => {
      httpParams = httpParams.append(key, String(value));
    });
    return httpParams;
  }

}
