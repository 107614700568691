import { Component, OnInit, TemplateRef } from "@angular/core";
import { Seccion } from "src/app/models/Seccion";
import { CatalogoService } from "src/app/services/catalogo.service";

import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
  selector: "app-secciones-servicios",
  templateUrl: "secciones-servicios.component.html"
})
export class SeccionesServiciosComponent implements OnInit {
  page:number = 1
  size:number = 5
  totalElements:number = 0
  lstSecciones:any[] = []
  seccionSelected:Seccion = new Seccion()
  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered modal-lg"
  };
  crearPermission:boolean = false
  editarPermission:boolean = false
  constructor( private modalService: BsModalService, private catalogoService:CatalogoService, private router: Router) {}

  ngOnInit() {
    let permisos:any[] = JSON.parse(localStorage.getItem("modulos"))
    let mp:any = permisos.find(x => x["modulo"]==="SERVICIOS")
    if(mp["permisos"]["crear"]){
      this.crearPermission = true
    }
    if(mp["permisos"]["editar"]){
      this.editarPermission = true
    }

    this.findAll()
  }

  findAll(){
    const formData = new FormData()
    formData.append("filtro","")
    formData.append("page",String(this.page-1))
    formData.append("size",String(this.size))
    this.catalogoService.findByPageSeccion(formData).subscribe(success=>{
      this.lstSecciones = success["content"]
      this.totalElements = success["totalElements"]
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  sweetAlert(icon,title:string,button:string,text:string){
    swal.fire({
      title: title,
      text: "",
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  openDefaultModal(modalDefault: TemplateRef<any>, seccion:Seccion) {
    this.seccionSelected = seccion
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }

  goToEdit(id:number){
    this.router.navigate([`/simex/secciones-servicios/editar-seccion-servicio/${id}`]);
  }

  pageChanged($event){
    this.page = $event["page"]
    this.findAll()
  }
}
