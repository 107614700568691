export class UsuarioDto{
  id:number
  nombre:string
  apellido_paterno:string
  apellido_materno:string
  nombre_usuario:string
  password:string = ""
  foto_perfil:string
  email:string
  passemail:string
}
