import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Cliente } from "src/app/models/Cliente";
import { ClienteArchivos } from "src/app/models/ClienteArchivos";
import { CatalogoService } from "src/app/services/catalogo.service";
import swal from "sweetalert2";
import * as $ from 'jquery';

@Component({
  selector: "app-editar-cliente",
  templateUrl: "editar-cliente.component.html"
})
export class EditarClienteComponent implements OnInit {
  id:number
  cliente:Cliente = new Cliente()
  lstArchivos:ClienteArchivos[] = []
  archivo:ClienteArchivos = new ClienteArchivos()
  deletePermission:boolean = false
  constructor(private catalogoService:CatalogoService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    let permisos:any[] = JSON.parse(localStorage.getItem("modulos"))
    console.log(permisos)
    let mp:any = permisos.find(x => x["modulo"]==="CLIENTES")
    console.log(mp)
    if(mp["permisos"]["eliminar"]){
      this.deletePermission = true
    }
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.findById()
  }

  findById(){
    this.catalogoService.findByIdCliente(this.id).subscribe(success=>{
      this.cliente = success
      this.findArchivosByIdCliente()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  findArchivosByIdCliente(){
    this.catalogoService.findArchivosByIdCliente(this.cliente.id).subscribe(success=>{
      this.lstArchivos = success
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  saveAlert(){
    var that = this
    swal.fire({
      title: '¿Estas seguro que deseas actualizar este cliente?',
      showDenyButton: true,
      confirmButtonText: 'Actualizar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.update()
      }
    })
  }

  update(){
    this.catalogoService.updateCliente(this.cliente).subscribe(success=>{
      console.log(success)
      this.swalNotification("Excelente!!","El cliente fue actualizado correctamente","success")
      this.router.navigate(['/simex/clientes'])
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  changeActiveAcceso(){
    this.cliente.acceso.activo = !this.cliente.acceso.activo
  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  changeFile($event){
    console.log($event.target.files[0])
    /**/
    this.archivo.file_name = $event.target.files[0].name
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    formData.append("id",String(this.cliente.id))
    formData.append("nombre",this.archivo.nombre)
    formData.append("content_type",$event.target.files[0].type)
    this.catalogoService.uploadFileCliente(formData).subscribe(success=>{
      this.archivo = new ClienteArchivos();
      $("#customFileLang").val("")
      this.swalNotification("Excelente!!","Se cargo el documento correctamente","success")
      this.findArchivosByIdCliente()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })

  }

  downloadFile(item:ClienteArchivos){
    this.catalogoService.downloadFileCliente(item.id).subscribe(success=>{

       // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });

      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);

       // Crea un enlace y simula el clic para descargar el archivo
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = item.file_name;
        a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  deleteFileAlert(item:ClienteArchivos){
    var that = this
    swal.fire({
      title: `¿Estas seguro que deseas eliminar el documento "${item.nombre}"?`,
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
      icon:"question"
    }).then((result) => {
      if (result.isConfirmed) {
        that.deleteFile(item)
      }
    })
  }

  deleteFile(item:ClienteArchivos){
    this.catalogoService.deleteFileCliente(item.id).subscribe(success=>{
      this.swalNotification("Excelente!!","Se eliminó el documento correctamente","success")
      this.findArchivosByIdCliente()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }
}
