import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModulosRolPermisos } from '../models/ModulosRolPermisos';
@Injectable({
  providedIn: 'root'
})
export class PermisosService {
  url:string = environment.url
  constructor(private http: HttpClient) { }

  findAll():Observable<any>{
    return this.http.get(`${this.url}/permission/v1/findAll`);
  }

  findAllModulos():Observable<any>{
    return this.http.get(`${this.url}/permission/v1/findAllModulos`)
  }

  save(lstModuloRolPermisos:ModulosRolPermisos[]):Observable<any>{
    return this.http.post(`${this.url}/permission/v1/save`,lstModuloRolPermisos)
  }

  update(lstModuloRolPermisos:ModulosRolPermisos[]):Observable<any>{
    return this.http.put(`${this.url}/permission/v1/update`, lstModuloRolPermisos)
  }

  findByRolId(id:number):Observable<any>{
    return this.http.get(`${this.url}/permission/v1/findByRolId/${id}`)
  }
}
