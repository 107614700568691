import { Component, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AuthGuard } from './auth-services/auth.guard';
import { AuthGuardLogin } from "./auth-services/auth.guard.login";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { LoginComponent } from "./pages/login/login.component";
import { UsuariosComponent } from "./pages/usuarios/usuarios.component";
import { SeccionesServiciosComponent } from "./pages/secciones-servicios/secciones-servicios.component";
import { ClientesComponent } from "./pages/clientes/clientes.component";
import { NuevoUsuarioComponent } from "./pages/usuarios/nuevo-usuario/nuevo-usuario.component";
import { NuevoClienteComponent } from "./pages/clientes/nuevo-cliente/nuevo-cliente.component";
import { NuevoSeccionesServiciosComponent } from "./pages/secciones-servicios/nuevo-secciones-servicios/nuevo-secciones-servicios.component";
import { RolesComponent } from "./pages/roles/roles.component";
import { NuevoRolComponent } from "./pages/roles/nuevo-rol/nuevo-rol.component";
import { UsuariosGuard } from "./auth-services/auth-services-modulos/usuarios.guard";
import { ServiciosGuard } from "./auth-services/auth-services-modulos/auth-servicios/servicios.guard";
import { ClientesGuard } from "./auth-services/auth-services-modulos/auth-clientes/clientes.guard";
import { EditarRolComponent } from "./pages/roles/editar-rol/editar-rol.component";
import { EditarUsuarioComponent } from "./pages/usuarios/editar-usuario/editar-usuario.component";
import { EditarSeccionesServiciosComponent } from "./pages/secciones-servicios/editar-secciones-servicios/editar-secciones-servicios.component";
import { EditarClienteComponent } from "./pages/clientes/editar-cliente/editar-cliente.component";
import { SolicitudesComponent } from "./pages/solicitudes/solicitudes.component";
import { InicioComponent } from "./pages/inicio/inicio.component";
import { NuevaSolicitudComponent } from "./pages/solicitudes/nueva-solicitud/nueva-solicitud.component";
import { SolicitudComponent } from "./pages/solicitudes/solicitud/solicitud.component";
import { EditarMiUsuarioComponent } from "./pages/usuarios/editar-mi-usuario/editar-mi-usuario.component";
import { VerClienteComponent } from "./pages/clientes/ver-cliente/ver-cliente.component";
import { ClientesEditarGuard } from "./auth-services/auth-services-modulos/auth-clientes/clientes-editar.guard";
import { ClientesCrearGuard } from "./auth-services/auth-services-modulos/auth-clientes/clientes-crear.guard";
import { ServiciosCrearGuard } from "./auth-services/auth-services-modulos/auth-servicios/servicios-crear.guard";
import { ServiciosEditarGuard } from "./auth-services/auth-services-modulos/auth-servicios/servicios-editar.guard";
import { SolicitudesCrearGuard } from "./auth-services/auth-services-modulos/auth-solicitudes/solicitudes-crear.guard";
import { SolicitudesEditarGuard } from "./auth-services/auth-services-modulos/auth-solicitudes/solicitudes-editar.guard";
import { SolicitudesGuard } from "./auth-services/auth-services-modulos/auth-solicitudes/solicitudes.guard";
import { FacturasComponent } from "./pages/facturas/facturas.component";
import { FacturasDetallesComponent } from "./pages/facturas/facturas-detalles/facturas-detalles.component";
import { FacturasActividadComponent } from "./pages/facturas/facturas-actividad/facturas-actividad.component";
import { SolicitudActividadComponent } from "./pages/solicitudes/solicitud-actividad/solicitud-actividad.component";
const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "login",
    component:LoginComponent,
    canActivate:[AuthGuardLogin]
  },
  {
    path: "simex",
    component: AdminLayoutComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path: 'inicio',
        component: InicioComponent
      },
      {
        path: "solicitudes",
        component: SolicitudesComponent,
        canActivate:[SolicitudesGuard]
      },
      {
        path: "solicitudes/nueva-solicitud/:id",
        component: NuevaSolicitudComponent,
        canActivate:[SolicitudesCrearGuard]
      },
      {
        path: "solicitudes/solicitud/:id",
        component: SolicitudComponent,
        canActivate:[SolicitudesGuard]
      },
      {
        path: 'solicitudes/actividad/:folio/:idsolicitud/:id',
        component: SolicitudActividadComponent
      },
      {
        path: "usuarios",
        component: UsuariosComponent,
        canActivate:[UsuariosGuard]
      },
      {
        path: "usuarios/nuevo-usuario",
        component:NuevoUsuarioComponent,
        canActivate:[UsuariosGuard]
      },
      {
        path: "usuarios/editar-usuario/:id",
        component: EditarUsuarioComponent,
        canActivate:[UsuariosGuard]
      },
      {
        path: "secciones-servicios",
        component: SeccionesServiciosComponent,
        canActivate:[ServiciosGuard]
      },
      {
        path: "secciones-servicios/nuevo-seccion-servicio",
        component:NuevoSeccionesServiciosComponent,
        canActivate:[ServiciosCrearGuard]
      },
      {
        path: "secciones-servicios/editar-seccion-servicio/:id",
        component:EditarSeccionesServiciosComponent,
        canActivate:[ServiciosEditarGuard]
      },
      {
        path: "clientes",
        component: ClientesComponent,
        canActivate:[ClientesGuard]
      },
      {
        path: 'clientes/nuevo-cliente',
        component: NuevoClienteComponent,
        canActivate:[ClientesCrearGuard]
      },
      {
        path: 'clientes/editar-cliente/:id',
        component: EditarClienteComponent,
        canActivate:[ClientesEditarGuard]
      },
      {
        path: 'clientes/ver-cliente/:id',
        component: VerClienteComponent
      },
      {
        path: 'roles',
        component: RolesComponent,
        canActivate:[UsuariosGuard]
      },
      {
        path: 'roles/nuevo-rol',
        component: NuevoRolComponent,
        canActivate:[UsuariosGuard]
      }
      ,
      {
        path: 'roles/editar-rol/:id',
        component: EditarRolComponent,
        canActivate:[UsuariosGuard]
      },
      {
        path: 'perfil',
        component: EditarMiUsuarioComponent
      },
      {
        path: 'facturas',
        component: FacturasComponent
      },
      {
        path: 'facturas-detalles/:folio',
        component: FacturasDetallesComponent
      },
      {
        path: 'facturas-actividad-detalles/:folio/:id',
        component: FacturasActividadComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
