<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a routerLink="/simex/solicitudes"> Solicitudes </a>
              </li>

              <li class="breadcrumb-item">
                <a> {{solicitud.folio}} </a>
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!--
          <a
            class="btn btn-sm btn-danger text-white"
            routerLink="/simex/solicitudes"
            style="cursor: pointer"
          >
            Cancelar
          </a>

          <span
            *ngIf="
              solicitud.estatus_solicitud.clave == 'PENDIENTE' &&
              editarPermission
            "
          >
            <a
              class="btn btn-sm btn-primary text-white"
              style="cursor: pointer"
              (click)="avanzarAlert()"
            >
              Solicitar información
            </a>
          </span>

          <span
            *ngIf="
              solicitud.estatus_solicitud.clave == 'SOLICITUD DE INFORMACION' &&
              editarPermission
            "
          >
            <a
              class="btn btn-sm btn-primary text-white"
              style="cursor: pointer"
              (click)="avanzarAlert()"
            >
              En revisión
            </a>
          </span>

          <span
            *ngIf="
              solicitud.estatus_solicitud.clave == 'EN REVISION' &&
              editarPermission
            "
          >
            <a
              class="btn btn-sm btn-primary text-white"
              style="cursor: pointer"
              (click)="regresarASolicitudInformacion()"
            >
              Solicitar información
            </a>

            <a
              class="btn btn-sm btn-neutral"
              style="cursor: pointer"
              (click)="avanzarAlert()"
            >
              Avanzar
            </a>
          </span>
          <span
            *ngIf="
              solicitud.estatus_solicitud.clave == 'EN PROCESO' &&
              editarPermission
            "
          >
            <a
              class="btn btn-sm btn-neutral"
              style="cursor: pointer"
              (click)="avanzarAlert()"
            >
              Finalizar
            </a>
          </span>
          -->

          <!--SOLICITAR INFORMACION-->
          <span *ngIf="editarPermission">
            <a
              class="btn btn-sm btn-primary text-white"
              style="cursor: pointer"
              (click)="regresarASolicitudInformacion()"
            >
              Solicitar información
            </a>
          </span>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-lg-5 col-5 text-left m-2">
              <h6 class="text-muted text-uppercase ls-1 mb-1">
                Administración
              </h6>
              <h2 class="h3 mb-0">Folio : {{ solicitud.folio }}</h2>
            </div>

            <div class="col-lg-6 col-5 text-right m-2">
              <!--
              <span class="badge badge-dot mr-4">
                <i
                  [class]="
                    getEstatusClass(solicitud.estatus_solicitud.clave) +
                    ' bg-dot'
                  "
                >
                </i>
                <span class="status">
                  {{ solicitud.estatus_solicitud.nombre }}
                </span>
              </span>-->
              <button class="btn btn-link" (click)="findById()">
                <i class="fa fa-recycle" aria-hidden="true"></i>
                Refrescar
              </button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <label>Cliente</label>
              <input
                type="text"
                class="form-control"
                [value]="solicitud.cliente.razon_social"
                disabled
              />
            </div>
            <div class="col-md-4">
              <label>Responsable de la actividad</label>
              <input
                type="text"
                class="form-control"
                [value]="solicitud.cliente.responsable_actividad"
                disabled
              />
            </div>
            <div class="col-md-4">
              <label>Correo electrónico</label>
              <input
                type="text"
                class="form-control"
                [value]="solicitud.cliente.email"
                disabled
              />
            </div>
          </div>
          <div class="row mt-2" *ngIf="solicitud.comentarios_solicitud != null">
            <div class="col-md-6">
              <label>Comentarios</label>
              <textarea
                class="form-control"
                cols="30"
                rows="5"
                [value]="solicitud.comentarios_solicitud"
                disabled
              ></textarea>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <th>Sección</th>
              <th>Servicio</th>
              <!--<th>Estatus</th>-->
              <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let item of solicitud.servicios; let i = index">
                <td style="white-space: pre-line">{{ item.seccion.nombre }}</td>
                <td style="white-space: pre-line">
                  {{ item.servicio.nombre }}
                </td>
                <!--
                <td>
                  <span
                    class="badge badge-dot mr-4"
                    *ngIf="item.estatus == 'Pendiente'"
                  >
                    <i class="bg-warning"></i>
                    <span class="status"> Pendiente </span>
                  </span>
                  <span
                    class="badge badge-dot mr-4"
                    *ngIf="item.estatus == 'En proceso'"
                  >
                    <i class="bg-success"></i>
                    <span class="status"> En proceso </span>
                  </span>
                  <span
                    class="badge badge-dot mr-4"
                    *ngIf="item.estatus == 'Finalizado'"
                  >
                    <i class="bg-danger"></i>
                    <span class="status"> Finalizado </span>
                  </span>
                </td>

                <td *ngIf="solicitud.estatus_solicitud.clave == 'EN PROCESO'">
                  <button
                    class="btn btn-secondary"
                    *ngIf="item.estatus == 'Pendiente'"
                    (click)="avanzarServicioAlert(item)"
                  >
                    Comenzar
                  </button>
                  <button
                    class="btn btn-success"
                    *ngIf="item.estatus == 'En proceso'"
                    (click)="avanzarServicioAlert(item)"
                  >
                    Finalizar
                  </button>
                </td>
                -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="col-lg-5 col-5 text-left m-2">
            <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>
            <h2 class="h3 mb-0">Plantillas</h2>
          </div>
        </div>
        <div class="card-body">
          <div class="row" *ngIf="isNotFinalizado">
            <div class="col-md-6">
              <label for="nombre-plantilla">Nombre documento</label>
              <input
                name="documentoTemplateNombre"
                id="nombre-plantilla"
                type="text"
                class="form-control"
                [(ngModel)]="documentoTemplateNombre"
              />
            </div>
            <div class="col-md-6">
              <label for="">Cargar plantilla</label>
              <div class="custom-file">
                <input
                  class="custom-file-input"
                  id="customFileLang inputPlantilla"
                  lang="en"
                  type="file"
                  (change)="uploadDocumentoTemplate($event)"
                  [disabled]="documentoTemplateNombre == ''"
                />
                <label class="custom-file-label" for="customFileLang">
                  Seleccionar archivo
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Plantilla</th>
                <th>Documento Cliente</th>
                <th>Comentarios</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let item of solicitud.documentosTemplateSolicitud;
                  index as i
                "
              >
                <td>
                  <div class="input-group">
                    <input
                      id="nombre-plantilla"
                      type="text"
                      class="form-control"
                      disabled
                      [value]="item.nombre_documento"
                    />
                    <button
                      class="btn btn-success"
                      type="button"
                      (click)="downloadDocumentoTemplate(item)"
                    >
                      <i class="fas fa-file-download"></i>
                    </button>
                    <button
                      class="btn btn-danger"
                      type="button"
                      (click)="deleteDocumentoTemplateAlert(item)"
                      *ngIf="isNotFinalizado"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td>
                <td>
                  <div
                    class="custom-file"
                    *ngIf="!item.cargo_cliente && isNotFinalizado"
                  >
                    <input
                      class="custom-file-input"
                      id="customFileLang inputPlantillaCliente"
                      lang="en"
                      type="file"
                      (change)="uploadDocumentoTemplateCliente($event, item.id)"
                    />
                    <label class="custom-file-label" for="customFileLang">
                      Seleccionar archivo
                    </label>
                  </div>

                  <div class="input-group" *ngIf="item.cargo_cliente">
                    <input
                      id="nombre-plantilla"
                      type="text"
                      class="form-control"
                      disabled
                      [value]="item.file_name_cliente"
                    />
                    <button
                      class="btn btn-success"
                      type="button"
                      (click)="downloadDocumentoTemplateCliente(item)"
                    >
                      <i class="fas fa-file-download"></i>
                    </button>
                    <button
                      class="btn btn-danger"
                      type="button"
                      (click)="deleteDocumentoTemplateClienteAlert(item)"
                      *ngIf="isNotFinalizado"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td>
                <td *ngIf="item.comentario != null && item.comentario != ''">
                  <textarea
                    class="form-control"
                    disabled
                    [value]="item.comentario"
                    cols="50"
                    rows="5"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="col-lg-5 col-5 text-left m-2">
            <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>
            <h2 class="h3 mb-0">Documentos</h2>
          </div>
        </div>
        <div class="card-body">
          <div class="row" *ngIf="isNotFinalizado">
            <div class="col-md-6">
              <label for="nombre-plantilla">Nombre documento</label>
              <input
                name="nombreDocumentoSolicitado"
                id="nombre-plantilla"
                type="text"
                class="form-control"
                [(ngModel)]="nombreDocumentoSolicitado"
              />
            </div>
            <div class="col-md-6">
              <br />
              <button
                class="btn btn-success"
                type="button"
                (click)="saveDocumentoSolicitado()"
                [disabled]="nombreDocumentoSolicitado == ''"
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Nombre documento</th>
                <th>Documento cliente</th>
                <th>Comentarios</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of solicitud.documentosSolicitados; index as i"
              >
                <td>{{ item.nombre_documento }}</td>
                <td>
                  <div
                    class="custom-file"
                    *ngIf="!item.cargado && isNotFinalizado"
                  >
                    <input
                      class="custom-file-input"
                      id="customFileLang inputDocCliente"
                      lang="en"
                      type="file"
                      (change)="uploadDocumentoSolicitado($event, item.id)"
                    />
                    <label class="custom-file-label" for="customFileLang">
                      Seleccionar archivo
                    </label>
                  </div>

                  <div class="input-group" *ngIf="item.cargado">
                    <input
                      id="nombre-doc"
                      type="text"
                      class="form-control"
                      disabled
                      [value]="item.file_name"
                    />
                    <button
                      class="btn btn-success"
                      type="button"
                      (click)="downloadDocumentoSolicitado(item)"
                    >
                      <i class="fas fa-file-download"></i>
                    </button>
                    <button
                      class="btn btn-danger"
                      type="button"
                      (click)="deleteDocumentoSolicitadoAlert(item)"
                      *ngIf="isNotFinalizado"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td>
                <td *ngIf="item.comentario != null && item.comentario != ''">
                  <textarea
                    class="form-control"
                    name="comentario_doc"
                    cols="30"
                    rows="5"
                    [value]="item.comentario"
                    disabled
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <div class="col-lg-12 col-5 m-2">
            <div class="row">
              <div class="col-md-6 text-left">
                <h6 class="text-muted text-uppercase ls-1 mb-1">
                  Administración
                </h6>
                <h2 class="h3 mb-0">Actividades</h2>
              </div>
              <div class="col-md-6 text-right" *ngIf="opEsp">
                <button class="btn btn-secondary" (click)="clickOpenModalNewActividad()">Agregar actividad</button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <th>Titulo</th>
              <th>estatus</th>
              <th>Fecha inicio</th>
              <th>Fecha fin</th>

              <th style="text-align: right">Acciones</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstActividad; let i = index">
                <td style="white-space: pre-line">{{ item.factura.titulo }}</td>
                <td style="white-space: pre-line">
                  {{ item.estatus }}
                </td>
                <td style="white-space: pre-line">
                  {{ item.fecha_inicio }}
                </td>
                <td style="white-space: pre-line">
                  {{ item.fecha_fin == null ? "N/A" : item.fecha_fin }}
                </td>
                <td>
                  <div class="text-right">
                    <div class="dropdown no-caret" dropdown [dropup]="'true'">
                      <a
                        aria-controls="dropdown-basic"
                        class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                        dropdownToggle
                        href="javascript:void(0)"
                        role="button"
                      >
                        <i class="fas fa-ellipsis-v"> </i>
                      </a>

                      <div
                        class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                        *dropdownMenu
                      >
                        <a
                          href="javascript:void(0)"
                          class="dropdown-item"
                          (click)="goToActividad(item.id)"
                        >
                          Ver
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="card-footer py-4"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--    MODALES    -->
<div>
  <!-- -->
  <button
    id="btn-modal-new-actividad"
    data-target="#modal-default"
    data-toggle="modal"
    type="button"
    (click)="openActividadModal(modalActividad)"
    hidden
  >
    Default
  </button>

  <ng-template #modalActividad>
    <div class="modal-header">
      <h6 class="modal-title" id="modal-title-default">Nueva actividad</h6>

      <button
        aria-label="Close"
        class="close"
        data-dismiss="modal"
        type="button"
        (click)="actividadModal.hide()"
      >
        <span aria-hidden="true"> × </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Titulo</label>
            <input
              type="text"
              class="form-control"
              name="titulo_actividad"
              id="titulo_actividad"
              [(ngModel)]="titulo"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Mensaje</label>
            <textarea
              name="mensaje_actividad"
              id="mensaje_actividad"
              cols="30"
              rows="7"
              class="form-control"
              [(ngModel)]="mensaje"
            ></textarea>
          </div>
        </div>
      </div>
      <h4>Servicios</h4>
      <div class="table-responsive">
        <table class="table align-items-center table-flush">
          <thead class="thead-light">
            <tr>
              <th>
                <div class="custom-control custom-checkbox mb-3">
                  <input
                    class="custom-control-input"
                    id="customCheck-all"
                    type="checkbox"
                    [checked]="serviciostsAll"
                    (change)="changeCheckedAll()"
                  />

                  <label class="custom-control-label" for="customCheck-all">
                  </label>
                </div>
              </th>
              <th>Sección</th>
              <th>Servicio</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of serviciosts; index as i">
              <td>
                <div class="custom-control custom-checkbox mb-3">
                  <input
                    class="custom-control-input"
                    id="customCheck-{{ i }}"
                    type="checkbox"
                    [checked]="item.checked"
                    (change)="changeCheckedServicio(i)"
                  />

                  <label class="custom-control-label" for="customCheck-{{ i }}">
                  </label>
                </div>
              </td>
              <td>{{ item.seccion.nombre }}</td>
              <td>{{ item.servicio.nombre }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal-footer">
      <button
        class="btn btn-link ml-auto"
        data-dismiss="modal"
        type="button"
        (click)="actividadModal.hide()"
      >
        Cerrar
      </button>
      <button
        class="btn btn-success"
        type="button"
        (click)="saveActividadAlert()"
      >
        Crear
      </button>
    </div>
  </ng-template>
</div>

<style>
  .bg-dot {
    height: 1rem;
    width: 1rem;
  }
</style>
