<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> Clientes </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-neutral"
            routerLink="/simex/clientes/nuevo-cliente"
            style="cursor: pointer"
            *ngIf="crearPermission"
          >
            Agregar
          </a>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>
          <h2 class="h3 mb-0">Clientes</h2>
        </div>
        <!--
        <div class="table-responsive">
          <ngx-datatable
            class="bootstrap selection-cell"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="10"
            [rows]="temp"
          >
            <ngx-datatable-column
              name="Razón social"
              prop="razon_social"
            ></ngx-datatable-column>
            <ngx-datatable-column
              name="Representante legal"
              prop="representante_legal"
            ></ngx-datatable-column>
            <ngx-datatable-column
              name="Fecha de registro"
              prop="fecha_creacion"
            ></ngx-datatable-column>
            <ngx-datatable-column name="Acciones" [width]="300">
              <ng-template ngx-datatable-cell-template let-row="row">
                <button class="btn btn-primary" (click)="goToVer(row.id)">
                  Ver &nbsp;
                  <i class="fas fa-eye"> </i>
                </button>
                <button
                  class="btn btn-success"
                  (click)="goToEdit(row.id)"
                  *ngIf="editarPermission"
                >
                  Editar &nbsp;
                  <i class="fas fa-user-edit"> </i>
                </button>
                <button
                  class="btn btn-secondary"
                  (click)="goToCrearSolicitud(row.id)"
                  *ngIf="crearPermissionSolicitudes"
                >
                  Crear solicitud
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
        -->
        <div class="table-responsive">
          <table class="table align-items-center table-flush">
            <thead class="thead-light">
              <tr>
                <th>Razón social</th>
                <th>Representante legal</th>
                <th>Fecha de registro</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstClientes, index as i">
                <td>{{item.razon_social}}</td>
                <td>{{item.representante_legal}}</td>
                <td>{{item.fecha_creacion}}</td>
                <td>
                  <button class="btn btn-primary" (click)="goToVer(item.id)">
                    Ver &nbsp;
                    <i class="fas fa-eye"> </i>
                  </button>
                  <button class="btn btn-success" (click)="goToEdit(item.id)" *ngIf="editarPermission">
                    Editar &nbsp;
                    <i class="fas fa-user-edit"> </i>
                  </button>
                  <button class="btn btn-secondary" (click)="goToCrearSolicitud(item.id)" *ngIf="crearPermissionSolicitudes">
                    Crear solicitud
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class=" card-footer py-4">
          <nav aria-label="...">
            <pagination [totalItems]="totalElements"
                        class="justify-content-end mb-0"
                        [customPreviousTemplate]="prevTemplate"
                        [customNextTemplate]="nextTemplate"
                        [itemsPerPage]="size"
                        [(ngModel)]="page"
                        (pageChanged)="pageChanged($event)"
                        [maxSize]="5">
            </pagination>

            <ng-template #nextTemplate let-currentPage="currentPage">
              <ng-container *ngIf="!disabled">
                <i class='fas fa-angle-right'></i>
              </ng-container>
            </ng-template>

            <ng-template #prevTemplate let-currentPage="currentPage">
              <ng-container *ngIf="!disabled">
                <i class='fas fa-angle-left'></i>
              </ng-container>
            </ng-template>

          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
