import { ComprobantePagoSolicitud } from "./ComprobantePagoSolicitud"
import { DocumentoFacturaMensual } from "./DocumentoFacturaMensual"
import { ServiciosFacturaMensual } from "./ServiciosFacturaMensual"

export class HistoricoFacturas{
  id:number
  titulo:string = ""
  mensaje:string = ""
  fecha:string
  servicios_factura_mensual: ServiciosFacturaMensual[] = []
  documentos_factura_mensual: DocumentoFacturaMensual[] = []
  comprobantes_pago_mensual: ComprobantePagoSolicitud[] = []
}
