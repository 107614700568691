<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a routerLink="/simex/roles"> Roles </a>
              </li>

              <li class="breadcrumb-item">
                <a> Editar rol </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-danger text-white"
            routerLink="/simex/roles"
            style="cursor: pointer"
          >
            Cancelar
          </a>

          <a
            class="btn btn-sm btn-neutral"
            style="cursor: pointer"
            (click)="saveAlert()"
          >
            Actualizar
          </a>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<!--
<div class=" header pb-6">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-4">
        <div class=" col-lg-6 col-7">

          <nav
            aria-label="breadcrumb"
            class=" d-none d-md-inline-block ml-md-4"
          >
            <ol class=" breadcrumb breadcrumb-links">
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
              </li>

              <li class=" breadcrumb-item">
                <a routerLink="/simex/usuarios"> Usuarios </a>
              </li>

              <li class=" breadcrumb-item">
                <a> Nuevo usuario </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class=" col-lg-6 col-5 text-right">

        </div>
      </div>
    </div>
  </div>
</div>
-->
<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>

          <h2 class="h3 mb-0">Editar Rol</h2>
        </div>
        <hr class="my-3" />

        <div class="card-body">
          <form>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer01">
                    Nombre
                  </label>

                  <input
                    class="form-control"
                    id="validationServer01"
                    placeholder="Nombre"
                    name="nombre"
                    required=""
                    type="text"
                    [(ngModel)]="rol.nombre"
                  />

                  <!--<div class="valid-feedback">Looks good!</div>-->
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <h6 class="text-muted text-uppercase ls-1 mb-1">
            Seleccion de permisos de los modulos
          </h6>
          <h2 class="h3 mb-0">Selección de modulos</h2>
        </div>
        <hr class="my-3" />
        <div class="card-body">
          <div class="table-responsive">
            <table class="table align-items-center table-flush">
              <thead>
                <tr>
                  <th>Modulo</th>
                  <th>Ver</th>
                  <th>Crear</th>
                  <th>Editar</th>
                  <th>Eliminar</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of lstModuloRolPermisos; index as i">
                  <td>{{ item.modulo_id.nombre }}</td>
                  <td>
                    <label class="custom-toggle custom-toggle-success mr-1">
                      <input
                        type="checkbox"
                        [checked]="item.ver == true ? 'checked' : ''"
                        (change)="changeActivePermission(i, 'ver')"
                      />
                      <span class="custom-toggle-slider rounded-circle"> </span>
                    </label>
                  </td>
                  <td>
                    <label class="custom-toggle mr-1">
                      <input
                        type="checkbox"
                        [checked]="item.crear == true ? 'checked' : ''"
                        (change)="changeActivePermission(i, 'crear')"
                      />
                      <span class="custom-toggle-slider rounded-circle"> </span>
                    </label>
                  </td>
                  <td>
                    <label class="custom-toggle mr-1">
                      <input
                        type="checkbox"
                        [checked]="item.editar == true ? 'checked' : ''"
                        (change)="changeActivePermission(i, 'editar')"
                      />
                      <span class="custom-toggle-slider rounded-circle"> </span>
                    </label>
                  </td>
                  <td>
                    <label class="custom-toggle mr-1">
                      <input
                        type="checkbox"
                        [checked]="item.eliminar == true ? 'checked' : ''"
                        (change)="changeActivePermission(i, 'eliminar')"
                      />
                      <span class="custom-toggle-slider rounded-circle"> </span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
