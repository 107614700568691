import { Component, OnInit, ElementRef } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import swal from "sweetalert2";
import { filter } from 'rxjs/operators';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = true;

  usuario:string = ""
  pathInputBuscar:string=""
  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router
  ) {
    this.location = location;
    this.router.events.subscribe((event: Event) => {
       if (event instanceof NavigationStart) {
           // Show loading indicator

       }
       if (event instanceof NavigationEnd) {
           // Hide loading indicator

           if (window.innerWidth < 1200) {
             document.body.classList.remove("g-sidenav-pinned");
             document.body.classList.add("g-sidenav-hidden");
             this.sidenavOpen = false;
           }
       }

       if (event instanceof NavigationError) {
           // Hide loading indicator

           // Present error to user
           console.log(event.error);
       }
   });

  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.usuario = localStorage.getItem("usuario")
    this.obtenerRutaActual()
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
      // Lógica a ejecutar cuando cambie la URL
      this.obtenerRutaActual();
    });

  }
  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  openSearch() {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function() {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  }
  closeSearch() {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  }
  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }


  logOut(){
    localStorage.removeItem("token")
    localStorage.removeItem("usuario")
    localStorage.removeItem("roles")
    localStorage.removeItem("modulos")
    this.router.navigate(['/login'])
  }


  logOutAlert(){
    var that = this
    swal.fire({
      title: '¿Estas seguro que deseas cerrar sesion?',
      showDenyButton: true,
      confirmButtonText: 'Cerrar sesion',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.logOut()
      }
    })
  }

  obtenerRutaActual() {
    const rutaActual = this.router.url;
    if(rutaActual=="/simex/usuarios"){
      this.pathInputBuscar = "USUARIOS"
    }else if(rutaActual=="/simex/clientes"){
      this.pathInputBuscar = "CLIENTES"
    }else if(rutaActual=="/simex/solicitudes"){
      this.pathInputBuscar = "SOLICITUDES"
    }else{
      this.pathInputBuscar = ""
    }
  }

  getFotoPerfil():string{
    //assets/img/theme/noimage.jpg
    if(localStorage.getItem("foto_perfil")!="null"){
      return localStorage.getItem("foto_perfil")
    }else{
      return "assets/img/theme/noimage.jpg"
    }
  }

  goToMiPerfil(){
    this.router.navigate(['/simex/perfil'])
  }
}
