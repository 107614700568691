import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from "src/app/models/Usuario";
import swal from "sweetalert2";
import { UsuarioDto } from "src/app/models/UsuarioDto";
import * as $ from 'jquery';
import { PerfilService } from "src/app/services/perfil.service";
@Component({
  selector: "app-editar-mi-usuario",
  templateUrl: "editar-mi-usuario.component.html"
})
export class EditarMiUsuarioComponent implements OnInit {
  id:number
  usuario:Usuario = new Usuario()
  usuarioDto:UsuarioDto = new UsuarioDto()
  constructor(private perfilService:PerfilService, private router: Router, private route: ActivatedRoute){}

  ngOnInit(): void {
    this.findUser()
  }

  findUser(){
    this.perfilService.getUser().subscribe(success=>{
      this.usuarioDto = success
      this.usuarioDto.password = ""
    },error=>{
      console.log(error)
    })
  }

  saveAlert(){
    var that = this
    swal.fire({
      title: '¿Estas seguro que deseas actualizar este usuario?',
      showDenyButton: true,
      confirmButtonText: 'Actualizar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.update()
      }
    })
  }

  update(){
    this.setUsuario()
    this.perfilService.update(this.usuario).subscribe(success=>{
      this.swalNotification("Excelente!!","Se actualizó tu perfil correctamente","success")
      this.usuario = new Usuario()
      this.usuarioDto = new UsuarioDto()
      this.findUser()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  uploadFoto(formData:FormData){
    this.perfilService.uploadFotoPerfil(formData).subscribe(success=>{
      var res:UsuarioDto = success
      this.usuarioDto.foto_perfil = res.foto_perfil
      localStorage.setItem("foto_perfil",this.usuarioDto.foto_perfil)
      this.swalNotification("Excelente!!","Se actualizó la foto de perfil correctamente","success")
      $("#fotoInput").val("")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
      $("#fotoInput").val("")
    })
  }

  changeFoto($event){
    const formData = new FormData();
    formData.append("file",$event.target.files[0])
    this.uploadFoto(formData)
  }

  getFotoPerfil():string{
    if(localStorage.getItem("foto_perfil")!="null"){
      return localStorage.getItem("foto_perfil")
    }else{
      return "assets/img/theme/noimage.jpg"
    }
  }

  setUsuario(){
    this.usuario.id = this.usuarioDto.id
    this.usuario.password = this.usuarioDto.password
    this.usuario.email = this.usuarioDto.email
    this.usuario.passemail = this.usuarioDto.passemail
  }
}
