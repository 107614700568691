import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ToastrModule } from "ngx-toastr";
import { TagInputModule } from "ngx-chips";
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { LoginModule } from "./pages/login/login.module";
//import { InicioModule } from 'src/app/pages/inicio/inicio.module';
import { BrowserModule } from '@angular/platform-browser';
import { ComponentsModule } from "./components/components.module";
//import { UsuariosModule } from "./pages/usuarios/usuarios.module";
//import { NuevoUsuarioModule } from "./pages/usuarios/nuevo-usuario/nuevo-usuario.module";
//import { NuevoClienteModule } from './pages/clientes/nuevo-cliente/nuevo-cliente.module'
import { SeccionesServiciosModule } from "./pages/secciones-servicios/secciones-servicios.module";

//import { NuevoSeccionesServiciosModule } from "./pages/secciones-servicios/nuevo-secciones-servicios/nuevo-secciones-servicios.module";

import { AppRoutingModule } from './app-routing.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from "./components/interceptors/auth.interceptor";
import { SpinnerModule } from "./components/spinner/spinner.module";
import { VerClienteModule } from "./pages/clientes/ver-cliente/ver-cliente.module";

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ComponentsModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    BrowserModule,
    AppRoutingModule,
    SpinnerModule,
    LoginModule,
    //InicioModule,
    //UsuariosModule,
    //NuevoUsuarioModule,
    //NuevoClienteModule,
    SeccionesServiciosModule,
    //NuevoSeccionesServiciosModule
    VerClienteModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
