import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Seccion } from "src/app/models/Seccion";
import { Servicio } from "src/app/models/Servicio";
import { CatalogoService } from "src/app/services/catalogo.service";
import swal from "sweetalert2";
@Component({
  selector: "app-editar-secciones-servicios",
  templateUrl: "editar-secciones-servicios.component.html"
})
export class EditarSeccionesServiciosComponent implements OnInit {
  id:number
  seccion:Seccion = new Seccion()
  servicio:Servicio = new Servicio()

  constructor(private catalogoService:CatalogoService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.findById()
  }

  findById(){
    this.catalogoService.findByIdSeccion(this.id).subscribe(success=>{
      this.seccion = success
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }
/*
  save(){
    this.catalogoService.saveSeccion(this.seccion).subscribe(success=>{
      console.log(success)
      this.swalNotification("Excelente!!","La sección fue creada correctamente","success")
      this.router.navigate(['/simex/secciones-servicios'])
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }
*/
  update(){
    this.catalogoService.updateSeccion(this.seccion).subscribe(success=>{
      console.log(success)
      this.swalNotification("Excelente!!","La sección fue actualizada correctamente","success")
      this.router.navigate(['/simex/secciones-servicios'])
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }


  saveAlert(){
    var that = this
    swal.fire({
      title: '¿Estas seguro que deseas actualizar esta sección?',
      showDenyButton: true,
      confirmButtonText: 'Actualizar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.update()
      }
    })
  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  addServicio(){
    this.seccion.servicios.push(this.servicio)
    this.servicio = new Servicio()
  }

  removeServicio(index:number){
    if(this.seccion.servicios[index].id != null){
      this.seccion.servicios[index].eliminado = !this.seccion.servicios[index].eliminado
    }else{
      this.seccion.servicios.splice(index, 1)
    }

  }

}
