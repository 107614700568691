import { Component, OnInit, OnDestroy } from "@angular/core";
import { UsuariosService } from "src/app/services/Usuarios.service";
import swal from "sweetalert2";
import * as $ from 'jquery';
import { Router } from "@angular/router";
import { Usuario } from "src/app/models/Usuario";
@Component({
  selector: "app-usuarios",
  templateUrl: "usuarios.component.html"
})
export class UsuariosComponent implements OnInit, OnDestroy {
  page:number = 1
  size:number = 5
  totalElements:number = 0
  inputBusqueda:string = ""
  lstUsuarios:any[] = []
  temp:any[] = []
  constructor(private usuarioService:UsuariosService, private router: Router) {}

  ngOnInit() {
    var that = this
    $(document).ready(function() {
      // Seleccionamos el input por su ID
      var $miInput = $("#input-buscar-usuario");

      // Agregamos el evento keypress al input
      $miInput.on("keypress", function(event) {
        // Comprobamos si la tecla presionada es "Enter" (código 13)
        if (event.which === 13) {
          // Aquí puedes realizar la acción que desees cuando se presione "Enter"
          that.searchData()
          // Evitamos el comportamiento por defecto del Enter en un formulario
          event.preventDefault();
        }
      });
    });


    this.findByPage()
  }


  ngOnDestroy(): void {
    $("#input-buscar-usuario").val("")
    //throw new Error("Method not implemented.");
  }


  findByPage(){
    const formData = new FormData()
    formData.append("filtro",this.inputBusqueda)
    formData.append("page",String(this.page-1))
    formData.append("size",String(this.size))
    this.usuarioService.findByPage(formData).subscribe(success=>{
      this.lstUsuarios = success["content"]
      this.totalElements = success["totalElements"]
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  sweetAlert(icon,title:string,button:string,text:string){
    swal.fire({
      title: title,
      text: "",
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  obtenerValor(){
    alert($("#input-buscar-usuario").val())
  }

  goToEdit(id:number){
    this.router.navigate([`/simex/usuarios/editar-usuario/${id}`]);
  }

  getFoto(foto:any):string{
    if(foto!=null){
      return foto
    }else{
      return "assets/img/theme/noimage.jpg"
    }
  }

  searchData(){
    this.inputBusqueda = String($("#input-buscar-usuario").val())
    this.page = 1
    this.findByPage()
    /*
    this.temp = this.lstUsuarios.filter((item) => {
      // Modifica esta lógica según tus necesidades
      return (
        (item.nombre+" "+item.apellido_paterno).toUpperCase().includes(search.toUpperCase()) ||
        item.nombre_usuario.toUpperCase().includes(search.toUpperCase()) ||
        item.fecha_creacion.toUpperCase().includes(search.toUpperCase())
      );
    });
    */
  }

  pageChanged($event){
    this.page = $event["page"]
    this.findByPage()
  }


}
