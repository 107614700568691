import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Cliente } from "src/app/models/Cliente";
import { Seccion } from "src/app/models/Seccion";
import { CatalogoService } from "src/app/services/catalogo.service";
import { SolicitudService } from "src/app/services/solicitud.service";
import swal from "sweetalert2";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-nueva-solicitud",
  templateUrl: "nueva-solicitud.component.html"
})
export class NuevaSolicitudComponent implements OnInit {
  id:number
  cliente:Cliente = new Cliente()
  lstServiciosSelected:any[] = []
  lstSecciones:Seccion[] = []
  lstSeccionesSelect:any[] = []
  idSeccionSelected:number = 1

  addServicioModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-dialog-centered modal-lg"
  };

  solicitudRequest:any = {
    cliente_id:0,
    servicios:[]
  }
  constructor(
    private solicitudService:SolicitudService,
    private catalogoService:CatalogoService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService) {}

  ngOnInit() {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.findClienteById()
    this.findAllSecciones()
  }


  findClienteById(){
    this.catalogoService.findByIdCliente(this.id).subscribe(success=>{
      this.cliente = success
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  findAllSecciones(){
    this.catalogoService.findAllSeccion().subscribe(success=>{
      this.lstSecciones = success
      this.idSeccionSelected = this.lstSecciones[0].id
      console.log("seccionSelected = ",this.idSeccionSelected)
      this.addCheckToServicios()
      this.getSeccionesToSelect()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  saveAlert(){
    var that = this
    swal.fire({
      title: '¿Estas seguro que deseas crear esta solicitud?',
      showDenyButton: true,
      confirmButtonText: 'Crear',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.solicitar()
      }
    })
  }

  solicitar(){
    this.buildSolicitudRequest()
    this.solicitudService.solicitar(this.solicitudRequest).subscribe(success=>{
      this.swalNotification("Excelente!!","La solicitud fue creada correctamente","success")
      this.router.navigate(['/simex/solicitudes'])
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })

  }

  getSeccionesToSelect(){
    for(let item of this.lstSecciones){
      this.lstSeccionesSelect.push({id:item.id, nombre:item.nombre})
    }
  }

  addCheckToServicios(){
    for(let i = 0;i < this.lstSecciones.length; i++){
      for(let e = 0;e < this.lstSecciones[i].servicios.length; e++){
        this.lstSecciones[i].servicios[e]["checked"] = false
      }
    }
  }

  changeCheckedServicio(i:number, e:number){
    let checked = !this.lstSecciones[i].servicios[e]["checked"]
    this.lstSecciones[i].servicios[e]["checked"] = checked
    if(checked){
      this.lstServiciosSelected.push({
        seccion_id:this.lstSecciones[i].id,
        seccion:this.lstSecciones[i].nombre,
        servicio_id:this.lstSecciones[i].servicios[e].id,
        servicio:this.lstSecciones[i].servicios[e].nombre,
        index_seccion:i,
        index_servicio:e
      })

    }else{
      this.removeFromSeccionesSelected(this.lstSecciones[i].servicios[e].id)
    }
  }

  removeFromSeccionesSelected(servicio_id:number){
    this.lstServiciosSelected = this.lstServiciosSelected.filter(x=> x.servicio_id !== servicio_id)
  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  openAddServicioModal(addServicioModal: TemplateRef<any>) {
    this.addServicioModal = this.modalService.show(addServicioModal, this.default);
  }

  buildSolicitudRequest(){
    this.solicitudRequest.cliente_id = this.cliente.id
    for(let item of this.lstServiciosSelected){
      this.solicitudRequest.servicios.push(item.servicio_id)
    }
  }
}
