import { Component, OnInit, OnDestroy } from "@angular/core";
import { UsuariosService } from "src/app/services/Usuarios.service";
import swal from "sweetalert2";
import * as $ from 'jquery';
import { RolService } from "src/app/services/rol.service";
import { Rol } from "src/app/models/Rol";
import { Router } from "@angular/router";
@Component({
  selector: "app-roles",
  templateUrl: "roles.component.html"
})
export class RolesComponent implements OnInit {
  lstRol:Rol[] = []
  constructor(private rolService:RolService, private router: Router) {}

  ngOnInit() {
    this.findAll()
  }

  findAll(){
    this.rolService.findAll().subscribe(success=>{
      this.lstRol = success
    },error=>{
      console.log(error)
      this.sweetAlert("error","Error al llamar servicios", "btn-warning","Favor de llamar al administrador")
    })
  }

  sweetAlert(icon,title:string,button:string,text:string){
    swal.fire({
      title: title,
      text: "",
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  goToEdit(id:number){
    this.router.navigate([`/simex/roles/editar-rol/${id}`]);
  }
}
