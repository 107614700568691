import { Rol } from "./Rol";
import { Modulo } from "./Modulo";
export class ModulosRolPermisos{
  id:number
  rol_id:Rol
  modulo_id:Modulo
  ver:boolean = false
  crear:boolean = false
  editar:boolean = false
  eliminar:boolean = false

  constructor(modulo_id:Modulo){
    this.modulo_id = modulo_id
  }
}
