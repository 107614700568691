import { Component, OnInit } from "@angular/core";
import { Authenticate } from 'src/app/models/Authenticate';
import { UsuariosService } from "src/app/services/Usuarios.service";
import { Router } from '@angular/router';
import swal from "sweetalert2";
@Component({
  selector: "app-login",
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit {
  focus;
  focus1;

  authenticate:Authenticate = new Authenticate()
  constructor(private usuarioService:UsuariosService, private router: Router) {}

  ngOnInit() {

  }

  login(){
    this.usuarioService.login(this.authenticate).subscribe(success=>{
      localStorage.setItem("token", success["jwtToken"])
      localStorage.setItem("usuario", success["usuario"])
      localStorage.setItem("roles", JSON.stringify(success["authorities"]))
      localStorage.setItem("modulos", JSON.stringify(success["lstModulo"]))
      localStorage.setItem("foto_perfil",success["foto_perfil"])

      let modulos:string = String(localStorage.getItem("modulos"))
      if(modulos.includes("SOLICITUDES")){
        this.router.navigate(['/simex/solicitudes']);
      }else if(modulos.includes("USUARIOS")){
        this.router.navigate(['/simex/usuarios']);
      }else if(modulos.includes("SERVICIOS")){
        this.router.navigate(['/simex/secciones-servicios']);
      }else if(modulos.includes("CLIENTES")){
        this.router.navigate(['/simex/clientes']);
      }else{
        this.router.navigate(['/simex/inicio']);
      }
    },error=>{
      console.log(error)
      if(error["status"] != undefined && error["status"] == "401"){
        this.alertInactivo(error.error["mensaje"])
      }else{
        this.alert()
      }
    })
  }

  alertInactivo(msj:string){
    swal.fire({
      title: "",
      text: msj,
      icon: "warning",
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  alert(){
    swal.fire({
      title: "El usuario o contraseña son incorrectos",
      text: "",
      icon: "warning",
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  successSwal() {
    swal.fire({
      title: "Se incio sesion correctamente",
      icon: "success",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-success"
      }
    });
  }

}
