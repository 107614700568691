import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { RolService } from "src/app/services/rol.service";
import { UsuariosService } from "src/app/services/Usuarios.service";
import { Usuario } from "src/app/models/Usuario";
import { Rol } from "src/app/models/Rol";
import swal from "sweetalert2";
@Component({
  selector: "app-nuevo-usuario",
  templateUrl: "nuevo-usuario.component.html"
})
export class NuevoUsuarioComponent implements OnInit {

  lstRoles:Rol[] = []
  lstRolesToSelect:any[] = []
  usuario:Usuario = new Usuario()
  constructor(private usuariosService:UsuariosService, private rolService:RolService, private router: Router){}

  ngOnInit(): void {
    this.findAllRol()
  }

  findAllRol(){
    this.rolService.findAll().subscribe(success=>{
      this.lstRoles = success
      for(let rol of this.lstRoles){

        this.lstRolesToSelect.push(
          {
            ...rol, ["activo"]:rol.nombre=='Usuario'?true:false
          }
        )
      }
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  changeActiveRol(i:number){
    this.lstRolesToSelect[i].activo = !this.lstRolesToSelect[i].activo
    console.log(this.lstRolesToSelect)
  }

  saveAlert(){
    var that = this
    swal.fire({
      title: '¿Estas seguro que deseas crear este usuario?',
      showDenyButton: true,
      confirmButtonText: 'Crear',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.save()
      }
    })
  }

  save(){
    for(let rol of this.lstRolesToSelect){
      if(rol.activo){
        this.usuario.roles.push(
          {
            "id":rol.id,
            "nombre":rol.nombre,
            "clave":rol.clave
          }
        )
      }
    }
    this.usuariosService.save(this.usuario).subscribe(success=>{
      this.swalNotification("Excelente!!","El usuario fue creado correctamente","success")
      this.router.navigate(['/simex/usuarios'])
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })

  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }
}
