<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)"> <i class="fas fa-home"> </i> </a>
              </li>

              <li class="breadcrumb-item">
                <a routerLink="/simex/clientes"> Clientes </a>
              </li>

              <li class="breadcrumb-item">
                <a> Nuevo cliente </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-danger text-white"
            routerLink="/simex/clientes"
            style="cursor: pointer"
          >
            Cancelar
          </a>

          <a class="btn btn-sm btn-neutral" style="cursor: pointer" (click)="saveAlert()">
            Guardar
          </a>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <h6 class="text-muted text-uppercase ls-1 mb-1">Administración</h6>
          <h2 class="h3 mb-0">Nuevo cliente</h2>
        </div>

        <hr class="my-3" />

        <div class="card-body">
          <form>

            <div class="form-row">
              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer01">
                    Razón social
                  </label>

                  <input
                    class="form-control"
                    id="validationServer01"
                    placeholder="Razón social"
                    required=""
                    name="razonsocial"
                    type="text"
                    [(ngModel)]="cliente.razon_social"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer02">
                    Dirección
                  </label>

                  <input
                    class="form-control"
                    id="validationServer02"
                    placeholder="Dirección"
                    required=""
                    name="direccion"
                    type="text"
                    [(ngModel)]="cliente.direccion"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label
                    class="form-control-label"
                    for="validationServerUsername"
                  >
                    Dirección fiscal
                  </label>

                  <input
                    aria-describedby="inputGroupPrepend3"
                    class="form-control"
                    id="validationServerUsername"
                    placeholder="Dirección fiscal"
                    required=""
                    name="direccionfisica"
                    type="text"
                    [(ngModel)]="cliente.direccion_fiscal"
                  />
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer01">
                    Regimen fiscal
                  </label>

                  <input
                    class="form-control"
                    id="validationServer01"
                    placeholder="Regimen fiscal"
                    required=""
                    name="regimenfiscal"
                    type="text"
                    [(ngModel)]="cliente.regimen_fiscal"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer02">
                    Permiso Comisión Reguladora de Energía
                  </label>

                  <input
                    class="form-control"
                    id="validationServer02"
                    placeholder="Permiso Comisión Reguladora de Energía"
                    required=""
                    name="pcre"
                    type="text"
                    [(ngModel)]="cliente.permiso_comision_regulatoria_energia"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label
                    class="form-control-label"
                    for="validationServerUsername"
                  >
                    Teléfono
                  </label>

                  <input
                    aria-describedby="inputGroupPrepend3"
                    class="form-control"
                    id="validationServerUsername"
                    placeholder="Teléfono"
                    required=""
                    name="telefono"
                    type="text"
                    [(ngModel)]="cliente.telefono"
                  />
                </div>
              </div>
            </div>

            <div class="form-row">

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer01">
                    Correo eléctronico
                  </label>

                  <input
                    class="form-control"
                    id="validationServer01"
                    placeholder="Representante legal"
                    required=""
                    name="correoelectronico"
                    type="text"
                    [(ngModel)]="cliente.email"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer01">
                    Representante legal
                  </label>

                  <input
                    class="form-control"
                    id="validationServer01"
                    placeholder="Representante legal"
                    required=""
                    name="representantelegar"
                    type="text"
                    [(ngModel)]="cliente.representante_legal"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer02">
                    Responsable de la actividad
                  </label>

                  <input
                    class="form-control"
                    id="validationServer02"
                    placeholder="Responsable de la actividad"
                    required=""
                    name="responsable_actividad"
                    type="text"
                    [(ngModel)]="cliente.responsable_actividad"
                  />
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label class="form-control-label" for="validationServer03">
                    RFC
                  </label>

                  <input
                    class="form-control"
                    id="validationServer03"
                    placeholder="RFC"
                    required=""
                    name="RFC"
                    type="text"
                    [(ngModel)]="cliente.rfc"
                  />
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>
