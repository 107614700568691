import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Authenticate } from 'src/app/models/Authenticate';
import { Cliente } from '../models/Cliente';
import { Seccion } from '../models/Seccion';
@Injectable({
  providedIn: 'root'
})
export class CatalogoService {
  url:string = environment.url
  constructor(private http: HttpClient) { }

  saveCliente(cliente:Cliente): Observable<any>{
    return this.http.post(`${this.url}/cliente/v1/save`,cliente)
  }

  findAllCliente(formData:FormData): Observable<any>{
    const params = this.formDataToHttpParams(formData);
    return this.http.get(`${this.url}/cliente/v1/findAll`,{params})
  }

  findByPageCliente(page:number): Observable<any>{
    return this.http.get(`${this.url}/cliente/v1/findByPage/${page}`)
  }

  findByIdCliente(id:number): Observable<any>{
    return this.http.get(`${this.url}/cliente/v1/findById/${id}`)
  }

  updateCliente(cliente:Cliente): Observable<any>{
    return this.http.put(`${this.url}/cliente/v1/update`,cliente)
  }

  findArchivosByIdCliente(id:number): Observable<any>{
    return this.http.get(`${this.url}/cliente/v1/findArchivosByIdCliente/${id}`)
  }

  uploadFileCliente(formData:FormData){
    return this.http.post(`${this.url}/cliente/v1/uploadFile`,formData)
  }

  downloadFileCliente(id:number): Observable<any>{
    return this.http.get(`${this.url}/cliente/v1/downloadFile/${id}`, {
      responseType: 'arraybuffer', // Indica que esperamos un array de bytes en la respuesta
    })
  }

  deleteFileCliente(id:number){
    return this.http.delete(`${this.url}/cliente/v1/deleteFile/${id}`)
  }

  saveSeccion(seccion:Seccion): Observable<any>{
    return this.http.post(`${this.url}/seccion/v1/save`,seccion)
  }

  findAllSeccion(): Observable<any>{
    return this.http.get(`${this.url}/seccion/v1/findAll`)
  }

  findByPageSeccion(formData:FormData): Observable<any>{
    const params = this.formDataToHttpParams(formData);
    return this.http.get(`${this.url}/seccion/v1/findByPage`, {params})
  }

  findByIdSeccion(id:number): Observable<any>{
    return this.http.get(`${this.url}/seccion/v1/findById/${id}`)
  }

  updateSeccion(seccion:Seccion): Observable<any>{
    return this.http.put(`${this.url}/seccion/v1/update`,seccion)
  }

  sendClaveAcceso(id:number): Observable<any>{
    return this.http.get(`${this.url}/cliente/v1/sendClaveAcceso/${id}`)
  }

  private formDataToHttpParams(formData: FormData): HttpParams {
    let httpParams = new HttpParams();
    formData.forEach((value, key) => {
      httpParams = httpParams.append(key, String(value));
    });
    return httpParams;
  }
}
