<div class="header pb-6">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links breadcrumb-light">
              <li class="breadcrumb-item">
                <a> <i class="fas fa-home"> </i> </a>
              </li>
              <li class="breadcrumb-item">
                <a> Mi perfil </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn btn-sm btn-danger text-white"
            routerLink="/simex/usuarios"
            style="cursor: pointer"
          >
            Cancelar
          </a>

          <a
            class="btn btn-sm btn-neutral"
            style="cursor: pointer"
            (click)="saveAlert()"
          >
            Actualizar
          </a>
          <!--
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
         -->
        </div>
      </div>
    </div>
  </div>
</div>

<!--
<div class=" header pb-6">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-4">
        <div class=" col-lg-6 col-7">

          <nav
            aria-label="breadcrumb"
            class=" d-none d-md-inline-block ml-md-4"
          >
            <ol class=" breadcrumb breadcrumb-links">
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
              </li>

              <li class=" breadcrumb-item">
                <a routerLink="/simex/usuarios"> Usuarios </a>
              </li>

              <li class=" breadcrumb-item">
                <a> Nuevo usuario </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class=" col-lg-6 col-5 text-right">

        </div>
      </div>
    </div>
  </div>
</div>
-->
<div class="container-fluid mt--6">
  <div class="row">
    <div class="col">
      <div class="card card-profile">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-md-10">
              <!--
              <h6 class="text-muted text-uppercase ls-1 mb-1">
                Administración
              </h6>
              -->
              <h2 class="h3 mb-0">Editar Perfil</h2>
            </div>
          </div>
        </div>
        <hr class="my-3" />
        <!--
        <div class="row justify-content-center">
          <div class=" col-lg-3 order-lg-2">
            <div class=" card-profile-image">
              <a href="javascript:void(0)">
                <img
                  class=" rounded-circle"
                  src="assets/img/theme/team-4.jpg"
                />
              </a>
            </div>
          </div>
        </div>
      -->

        <div class=" row justify-content-center">
          <div class=" col-lg-3 order-lg-2">
            <div class=" card-profile-image" (click)="fotoInput.click()">
              <a href="javascript:void(0)">
                <img
                  class=" rounded-circle"
                  [src]="getFotoPerfil()"
                />

              </a>
            </div>
            <input #fotoInput type="file" id="fotoInput" style="display: none;" (change)="changeFoto($event)">
          </div>
        </div>
        <div class=" card-header"></div>
        <br>
        <div class="card-body border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          <div class="form-row">
            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label class="form-control-label" for="validationServer01">
                  Nombre
                </label>

                <input
                  class="form-control"
                  id="validationServer01"
                  placeholder="Nombre"
                  required=""
                  name="nombre"
                  type="text"
                  [value]="usuarioDto.nombre"
                  disabled
                />

                <!--<div class="valid-feedback">Looks good!</div>-->
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label class="form-control-label" for="validationServer02">
                  Apellido paterno
                </label>

                <input
                  class="form-control"
                  id="validationServer02"
                  placeholder="Apellido paterno"
                  required=""
                  name="apellido_paterno"
                  type="text"
                  [value]="usuarioDto.apellido_paterno"
                  disabled
                />

                <!--<div class="valid-feedback">Looks good!</div>-->
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label
                  class="form-control-label"
                  for="validationServerUsername"
                >
                  Apellido materno
                </label>

                <input
                  aria-describedby="inputGroupPrepend3"
                  class="form-control"
                  id="validationServerUsername"
                  placeholder="Apellido materno"
                  required=""
                  name="apellido_materno"
                  type="text"
                  [value]="usuarioDto.apellido_materno"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label class="form-control-label"> Correo electrónico </label>
                <input
                  class="form-control"
                  placeholder="Correo electrónico"
                  name="email"
                  type="text"
                  [(ngModel)]="usuarioDto.email"
                />
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label class="form-control-label"> Nombre usuario </label>
                <input
                  class="form-control"
                  placeholder="Nombre usuario"
                  name="nombre_usuario"
                  type="text"
                  [value]="usuarioDto.nombre_usuario"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label class="form-control-label"> Contraseña </label>

                <input
                  class="form-control"
                  placeholder="Contraseña"
                  type="password"
                  name="password"
                  [(ngModel)]="usuarioDto.password"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="col">
      <div class="card">
        <div class="card-header border-0">
          <h2 class="h3 mb-0">Correo electrónico</h2>
        </div>
        <hr class="my-3" />
        <div class="card-body">

          <div class="form-row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label class="form-control-label"> Correo electrónico </label>
                <input
                  class="form-control"
                  placeholder="Correo electrónico"
                  name="email"
                  type="text"
                  [(ngModel)]="usuarioDto.email"
                />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label class="form-control-label"> Contraseña </label>
                <input
                  class="form-control"
                  placeholder="Contraseña"
                  name="passemail"
                  type="text"
                  [(ngModel)]="usuarioDto.passemail"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    -->
  </div>
</div>
