import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class  ServiciosGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

      return new Promise<boolean>((resolve, reject) => {
        let modulos:string = String(localStorage.getItem("modulos"))
        if(modulos.includes("SERVICIOS")){
          resolve(true)
        }else{
          let modulos:string = String(localStorage.getItem("modulos"))
          if(modulos.includes("SOLICITUDES")){
            this.router.navigate(['/simex/solicitudes']);
          }else if(modulos.includes("USUARIOS")){
            this.router.navigate(['/simex/usuarios']);
          }else if(modulos.includes("SERVICIOS")){
            this.router.navigate(['/simex/secciones-servicios']);
          }else if(modulos.includes("CLIENTES")){
            this.router.navigate(['/simex/clientes']);
          }else{
            this.router.navigate(['/simex/inicio']);
          }
          resolve(false)
        }
      })
    }
  }
