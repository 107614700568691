<nav
  class="navbar navbar-top navbar-expand navbar-light border-bottom"
  id="navbar-main"
>
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Search form -->
      <form
        class="navbar-search navbar-search-light form-inline mr-sm-3"
        id="navbar-search-main"
      >
        <div class="form-group mb-0" [ngClass]="{ focused: focus === true }" *ngIf="pathInputBuscar=='USUARIOS'">
          <div class="input-group input-group-alternative input-group-merge">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fas fa-search"></i
              ></span>
            </div>
            <input
              id="input-buscar-usuario"
              class="form-control"
              placeholder="Buscar"
              type="text"
              (focus)="focus = true"
              (blur)="focus = false"
            />
          </div>
        </div>



        <div class="form-group mb-0" [ngClass]="{ focused: focus === true }" *ngIf="pathInputBuscar=='CLIENTES'">
          <div class="input-group input-group-alternative input-group-merge">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fas fa-search"></i
              ></span>
            </div>
            <input
            id="input-buscar-cliente"
            class="form-control"
            placeholder="Buscar"
            type="text"
            (focus)="focus = true"
            (blur)="focus = false"
          />
          </div>
        </div>

        <div class="form-group mb-0" [ngClass]="{ focused: focus === true }" *ngIf="pathInputBuscar=='SOLICITUDES'">
          <div class="input-group input-group-alternative input-group-merge">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fas fa-search"></i
              ></span>
            </div>
            <input
              id="input-buscar-solicitud"
              class="form-control"
              placeholder="Buscar"
              type="text"
              (focus)="focus = true"
              (blur)="focus = false"
            />
          </div>
        </div>


        <button
          type="button"
          class="close"
          (click)="closeSearch()"
          data-action="search-close"
          data-target="#navbar-search-main"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </form>
      <!-- Navbar links -->
      <ul class="navbar-nav align-items-center ml-md-auto">
        <li class="nav-item d-xl-none">
          <!-- Sidenav toggler -->
          <div
            class="pr-3 sidenav-toggler sidenav-toggler-dark"
            data-action="sidenav-pin"
            data-target="#sidenav-main"
            (click)="openSidebar()"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
        <li class="nav-item d-sm-none">
          <a class="nav-link" (click)="openSearch()">
            <i class="ni ni-zoom-split-in"></i>
          </a>
        </li>

        <li *ngIf="false" class="nav-item dropdown" dropdown placement="bottom-right">
          <a class="nav-link dropdown-toggle" role="button" dropdownToggle>
            <i class="ni ni-bell-55"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
            *dropdownMenu
          >
            <!-- Dropdown header -->
            <div class="px-3 py-3">
              <h6 class="text-sm text-muted m-0">
                You have <strong class="text-primary">13</strong> notifications.
              </h6>
            </div>
            <!-- List group -->
            <div class="list-group list-group-flush">
              <a
                href="javascript:void()"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-1.jpg"
                      class="avatar rounded-circle"
                    />
                  </div>
                  <div class="col ml--2">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div><h4 class="mb-0 text-sm">John Snow</h4></div>
                      <div class="text-right text-muted">
                        <small>2 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      Let's meet at Starbucks at 11:30. Wdyt?
                    </p>
                  </div>
                </div>
              </a>
              <a
                href="javascript:void()"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-2.jpg"
                      class="avatar rounded-circle"
                    />
                  </div>
                  <div class="col ml--2">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div><h4 class="mb-0 text-sm">John Snow</h4></div>
                      <div class="text-right text-muted">
                        <small>3 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      A new issue has been reported for Argon.
                    </p>
                  </div>
                </div>
              </a>
              <a
                href="javascript:void()"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-3.jpg"
                      class="avatar rounded-circle"
                    />
                  </div>
                  <div class="col ml--2">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div><h4 class="mb-0 text-sm">John Snow</h4></div>
                      <div class="text-right text-muted">
                        <small>5 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      Your posts have been liked a lot.
                    </p>
                  </div>
                </div>
              </a>
              <a
                href="javascript:void()"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-4.jpg"
                      class="avatar rounded-circle"
                    />
                  </div>
                  <div class="col ml--2">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div><h4 class="mb-0 text-sm">John Snow</h4></div>
                      <div class="text-right text-muted">
                        <small>2 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      Let's meet at Starbucks at 11:30. Wdyt?
                    </p>
                  </div>
                </div>
              </a>
              <a
                href="javascript:void()"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-5.jpg"
                      class="avatar rounded-circle"
                    />
                  </div>
                  <div class="col ml--2">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div><h4 class="mb-0 text-sm">John Snow</h4></div>
                      <div class="text-right text-muted">
                        <small>3 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      A new issue has been reported for Argon.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <!-- View all -->
            <a
              href="javascript:void()"
              class="dropdown-item text-center text-primary font-weight-bold py-3"
              >View all</a
            >
          </div>
        </li>
        <li *ngIf="false" class="nav-item dropdown" dropdown placement="bottom-right">
          <a
            class="nav-link dropdown-toggle"
            href="javascript:void(0)"
            role="button"
            dropdownToggle
          >
            <i class="ni ni-ungroup"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
            *dropdownMenu
          >
            <div class="row shortcuts px-4">
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span
                  class="shortcut-media avatar rounded-circle bg-gradient-red"
                >
                  <i class="ni ni-calendar-grid-58"></i>
                </span>
                <small>Calendar</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span
                  class="shortcut-media avatar rounded-circle bg-gradient-orange"
                >
                  <i class="ni ni-email-83"></i>
                </span>
                <small>Email</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span
                  class="shortcut-media avatar rounded-circle bg-gradient-info"
                >
                  <i class="ni ni-credit-card"></i>
                </span>
                <small>Payments</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span
                  class="shortcut-media avatar rounded-circle bg-gradient-green"
                >
                  <i class="ni ni-books"></i>
                </span>
                <small>Reports</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span
                  class="shortcut-media avatar rounded-circle bg-gradient-purple"
                >
                  <i class="ni ni-pin-3"></i>
                </span>
                <small>Maps</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span
                  class="shortcut-media avatar rounded-circle bg-gradient-yellow"
                >
                  <i class="ni ni-basket"></i>
                </span>
                <small>Shop</small>
              </a>
            </div>
          </div>
        </li>

      </ul>
      <!-- User -->
      <ul class="navbar-nav align-items-center ml-auto ml-md-0">
        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <a class="nav-link pr-0 dropdown-toggle" role="button" dropdownToggle>
            <div class="media align-items-center" style="cursor: pointer;">
              <span class="avatar avatar-sm rounded-circle">
                <img
                  alt="Image placeholder"
                  [src]="getFotoPerfil()"
                />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm  font-weight-bold">{{usuario}}</span>
              </div>
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-menu-arrow dropdown-menu-right"
            *dropdownMenu
          >
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Bienvenid@!</h6>
            </div>
            <!--
            <a
              routerLinkActive="active"
              [routerLink]="['/user-profile']"
              class="dropdown-item"
            >
              <i class="ni ni-single-02"></i> <span>My profile</span>
            </a>
            <a
              routerLinkActive="active"
              [routerLink]="['/user-profile']"
              class="dropdown-item"
            >
              <i class="ni ni-settings-gear-65"></i> <span>Settings</span>
            </a>
            <a
              routerLinkActive="active"
              [routerLink]="['/user-profile']"
              class="dropdown-item"
            >
              <i class="ni ni-calendar-grid-58"></i> <span>Activity</span>
            </a>
            <a
              routerLinkActive="active"
              [routerLink]="['/examples/user-profile']"
              class="dropdown-item"
            >
              <i class="ni ni-support-16"></i> <span>Support</span>
            </a>
            -->
            <div class="dropdown-divider"></div>
            <a (click)="goToMiPerfil()" style="cursor: pointer;" class="dropdown-item">
              <i class="fas fa-user-edit"></i> <span>Editar perfil</span>
            </a>
            <a (click)="logOutAlert()" style="cursor: pointer;" class="dropdown-item">
              <i class="ni ni-user-run"></i> <span>Cerrar sesion</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div
  *ngIf="sidenavOpen === true"
  class="backdrop d-xl-none"
  (click)="toggleSidenav()"
></div>
