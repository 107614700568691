import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Cliente } from "src/app/models/Cliente";
import { CatalogoService } from "src/app/services/catalogo.service";

import swal from "sweetalert2";

@Component({
  selector: "app-nuevo-cliente",
  templateUrl: "nuevo-cliente.component.html"
})
export class NuevoClienteComponent implements OnInit {

  cliente:Cliente = new Cliente()
  constructor(private catalogoService:CatalogoService, private router: Router) {}

  ngOnInit() {}

  saveAlert(){
    var that = this
    swal.fire({
      title: '¿Estas seguro que deseas crear este cliente?',
      showDenyButton: true,
      confirmButtonText: 'Crear',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        that.save()
      }
    })
  }

  save(){
    this.catalogoService.saveCliente(this.cliente).subscribe(success=>{
      console.log(success)
      this.swalNotification("Excelente!!","El cliente fue creado correctamente","success")
      this.router.navigate(['/simex/clientes'])
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })

  }

  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

}
