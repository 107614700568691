export class DocumentoSolicitado{
  id:number
  nombre_documento:string
  content_type:string
  file_name:string
  real_file_name:string
  cargado:boolean = false
  fecha_creacion:string
  fecha_actualizacion:string
  comentario:string
}
