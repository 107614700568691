import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Cliente } from "src/app/models/Cliente";
import { ClienteArchivos } from "src/app/models/ClienteArchivos";
import { CatalogoService } from "src/app/services/catalogo.service";
import swal from "sweetalert2";
import * as $ from 'jquery';

@Component({
  selector: "app-ver-cliente",
  templateUrl: "ver-cliente.component.html"
})
export class VerClienteComponent implements OnInit {
  id:number
  cliente:Cliente = new Cliente()
  lstArchivos:ClienteArchivos[] = []
  archivo:ClienteArchivos = new ClienteArchivos()
  editarPermission:boolean = false
  constructor(private catalogoService:CatalogoService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    let permisos:any[] = JSON.parse(localStorage.getItem("modulos"))
    console.log(permisos)
    let mp:any = permisos.find(x => x["modulo"]==="CLIENTES")
    console.log(mp)
    if(mp["permisos"]["editar"]){
      this.editarPermission = true
    }
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.findById()
  }

  findById(){
    this.catalogoService.findByIdCliente(this.id).subscribe(success=>{
      this.cliente = success
      this.findArchivosByIdCliente()
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  findArchivosByIdCliente(){
    this.catalogoService.findArchivosByIdCliente(this.cliente.id).subscribe(success=>{
      this.lstArchivos = success
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }


  swalNotification(titile:string,text:string,icon:any){
    swal.fire({
      title: titile,
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText:'Aceptar',
      customClass: {
        confirmButton: "btn btn-warning"
      }
    });
  }

  downloadFile(item:ClienteArchivos){
    this.catalogoService.downloadFileCliente(item.id).subscribe(success=>{

       // Crear un Blob a partir del ByteArray
      const blob = new Blob([success], { type: item.content_type });

      // Crear una URL Blob a partir del Blob
      const blobUrl = URL.createObjectURL(blob);

       // Crea un enlace y simula el clic para descargar el archivo
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = item.file_name;
        a.click();
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

  goToEdit(){
    this.router.navigate([`/simex/clientes/editar-cliente/${this.id}`]);
  }

  sendClaveAcceso(){
    this.catalogoService.sendClaveAcceso(this.cliente.id).subscribe(success=>{
      this.swalNotification("Excelente!!","Se envió la clave de acceso correctamente","success")
    },error=>{
      console.log(error)
      this.swalNotification("Error al llamar servicios","favor de contactar al administrador","error")
    })
  }

}
