<div class="container-fluid">
  <footer class="footer">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6">
        <div class="copyright text-center text-xl-left text-muted">
          &copy; {{ test | date: "yyyy" }}
          <a
            href="https://www.creative-tim.com?ref=adpa-footer-admin-layout"
            class="font-weight-bold ml-1"
            target="_blank"
            >Creative Tim</a
          >
        </div>
      </div>
      <div class="col-xl-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-xl-end"
        >
          <li class="nav-item">
            <a
              href="https://www.creative-tim.com?ref=adpa-footer-admin-layout"
              class="nav-link"
              target="_blank"
              >Creative Tim</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://www.creative-tim.com/presentation?ref=adpa-footer-admin-layout"
              class="nav-link"
              target="_blank"
              >About Us</a
            >
          </li>
          <li class="nav-item">
            <a
              href="http://blog.creative-tim.com?ref=adpa-footer-admin-layout"
              class="nav-link"
              target="_blank"
              >Blog</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://www.creative-tim.com/license"
              class="nav-link"
              target="_blank"
              >License</a
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
</div>
